import { call, put, select } from 'redux-saga/effects';
import { LogComponent, LogStatus } from '../errorHandling/constants';
import { selectFeatureFlags } from '../feature-flags';
import { toLogError } from '../services/errors/error-handler';
import { cancelPayment, getPaymentDevices, getPaymentStatus, startPayment, } from '../services/sales-api-client/sales-api-payment';
import { logClientToCloud } from '../store/actions';
import { delay } from '../utils/sagas-utils';
import { selectEmvPaymentModuleState } from './emvPaymentModule-selectors';
import { clearTransactionState, emvPaymentError, emvPaymentSuccessful, pollTransactionStatus, setEmvMode, setEmvSupported, setError, setLoadingDevices, setPaymentDetails, setPaymentDevices, setPaymentReference, setStatus, shouldRequireFullName, shouldSelectDeviceFromList, } from './emvPaymentModule-slice';
import { TransactionStatus } from './emvPaymentModule-types';
import { EMVService } from './EMVService';
import messages, { mapErrorCodeToErrorMessage, PDG_REQUEST_UNREACHABLE } from './payment-messages';
const PAYMENT_STATUS_REQUEST_DELAY = 2000;
class PdgPaymentModule {
    *isAvailable(permissions) {
        const featureFlags = yield select(selectFeatureFlags);
        return permissions.canUsePdg && featureFlags.paymentDeviceGateway;
    }
    *initialize() {
        yield put(setEmvMode(EMVService.Pdg));
        yield put(setEmvSupported(true));
        yield put(shouldSelectDeviceFromList());
        yield put(shouldRequireFullName());
    }
    *fetchPaymentDevices() {
        yield put(setLoadingDevices());
        const devices = yield call(getPaymentDevices);
        yield put(setPaymentDevices(devices));
    }
    *acknowledgeTransaction() { }
    *reverseTransaction() { }
    *processTransaction(action) {
        yield put(clearTransactionState());
        yield put(setStatus(TransactionStatus.STARTING));
        const emvInfo = yield select(selectEmvPaymentModuleState);
        if (!action.data.eventCode || action.data.isManualEntry === undefined || !emvInfo.deviceId) {
            yield put(emvPaymentError());
            return;
        }
        let firstError;
        const onError = (salesApiErrors) => {
            firstError = salesApiErrors[0];
        };
        const paymentReference = yield call(startPayment, emvInfo.deviceId, action.data.eventId, action.data.eventCode, action.data.systemId, action.data.operatorCode, action.data.isManualEntry, action.data.amount, action.data.transactionCurrency, onError, action.data.qrCodeProvider);
        if (firstError) {
            yield call(handleErrors, firstError);
        }
        else {
            yield put(setPaymentReference(paymentReference));
            yield put(pollTransactionStatus());
        }
    }
    *pollTransactionStatus() {
        var _a, _b;
        let response;
        let clientNetworkErrorOccurred = false;
        let criticalErrorOccurred = false;
        let transactionInProgress = false;
        let firstError;
        const onError = (salesApiErrors) => {
            firstError = salesApiErrors[0];
        };
        do {
            const { transaction } = yield select(selectEmvPaymentModuleState);
            try {
                clientNetworkErrorOccurred = false;
                response = yield call(getPaymentStatus, transaction.paymentReference, onError);
                if (!firstError &&
                    response &&
                    (transaction.status !== TransactionStatus.CANCELLING ||
                        response.transaction.status === TransactionStatus.CANCELLED)) {
                    yield put(setStatus(response.transaction.status));
                }
            }
            catch (error) {
                clientNetworkErrorOccurred = true;
                yield call(logMessage, LogStatus.INFO, 'An error occurred whilst calling PDG paymentStatus', toLogError(error), transaction.paymentReference);
            }
            criticalErrorOccurred = yield call(hasCriticalError, clientNetworkErrorOccurred, firstError, transaction.paymentReference);
            transactionInProgress = !response || ((_a = response === null || response === void 0 ? void 0 : response.transaction) === null || _a === void 0 ? void 0 : _a.status) === TransactionStatus.PROCESSING;
            if (transactionInProgress) {
                yield delay(PAYMENT_STATUS_REQUEST_DELAY);
            }
        } while (!criticalErrorOccurred && transactionInProgress);
        if (!response || TransactionStatus.SUCCESS !== ((_b = response === null || response === void 0 ? void 0 : response.transaction) === null || _b === void 0 ? void 0 : _b.status)) {
            yield call(handlePaymentStatusErrors, firstError, response === null || response === void 0 ? void 0 : response.transaction);
        }
        else {
            yield put(setPaymentDetails({
                lastFourDigits: response.card.lastFourDigits,
                cardType: response.card.type,
            }));
            yield put(emvPaymentSuccessful());
        }
    }
    *clearTransaction() {
        yield put(clearTransactionState());
    }
    *cancelTransaction() {
        var _a, _b, _c, _d;
        yield put(setStatus(TransactionStatus.CANCELLING));
        const { transaction } = yield select(selectEmvPaymentModuleState);
        let errors = [];
        const onError = (salesApiErrors) => {
            errors = salesApiErrors;
        };
        yield call(cancelPayment, transaction.paymentReference, onError);
        if (errors.length) {
            yield put(setError({
                terminalError: (_b = (_a = errors[0]) === null || _a === void 0 ? void 0 : _a.message) !== null && _b !== void 0 ? _b : '',
                errorMessage: mapErrorCodeToErrorMessage((_d = (_c = errors[0]) === null || _c === void 0 ? void 0 : _c.code) !== null && _d !== void 0 ? _d : ''),
            }));
        }
    }
}
export function* hasCriticalError(clientNetworkError, error, paymentReference) {
    const networkError = yield call(hasNetworkError, clientNetworkError, error, paymentReference);
    return !networkError && error !== undefined;
}
export function* hasNetworkError(clientNetworkError, error, paymentReference) {
    const networkError = (error === null || error === void 0 ? void 0 : error.code) === PDG_REQUEST_UNREACHABLE || clientNetworkError;
    if (networkError) {
        yield call(logMessage, LogStatus.INFO, `Unable to get payment status for transaction ${paymentReference} due to network error, retrying...`);
    }
    return networkError;
}
export function* handlePaymentStatusErrors(firstError, transaction) {
    if (firstError) {
        yield call(handleErrors, firstError);
    }
    else if (transaction) {
        const error = {};
        if (transaction.error) {
            error.errorMessage = mapErrorCodeToErrorMessage(transaction.error);
        }
        else {
            switch (transaction.status) {
                case TransactionStatus.FAILURE:
                    error.errorMessage = messages.nakEMVPaymentWarningMsg;
                    break;
                case TransactionStatus.CANCELLED:
                    error.errorMessage = messages.cancelEMVPaymentWarningMsg;
                    error.isWarning = true;
                    break;
                default:
                    error.errorMessage = messages.checkoutEMVPaymentErrMsg;
            }
        }
        yield put(setError(error));
        yield put(emvPaymentError());
    }
    else {
        yield put(setError({ errorMessage: messages.paymentDeviceGatewayGeneralFailure }));
        yield put(emvPaymentError());
    }
}
export function* handleErrors(firstError) {
    var _a, _b;
    yield put(setError({
        terminalError: firstError === null || firstError === void 0 ? void 0 : firstError.message,
        errorMessage: mapErrorCodeToErrorMessage((_b = (_a = firstError === null || firstError === void 0 ? void 0 : firstError.extensions) === null || _a === void 0 ? void 0 : _a.errorCode) !== null && _b !== void 0 ? _b : ''),
    }));
    yield put(setStatus(TransactionStatus.FAILURE));
    yield put(emvPaymentError());
}
function* logMessage(logStatus, message, ...data) {
    yield put(logClientToCloud({
        type: LogComponent.PDG,
        status: logStatus,
        data: {
            message,
            data,
        },
    }));
}
export default new PdgPaymentModule();
