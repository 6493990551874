import { createSelector } from 'reselect';
import { TicketTypesByOfferSetAndRate } from './ticket-type-by-offerset-and-rate';
import { selectTicketTypes } from './ticketType';
import type { TicketTypeAndPrices } from '../../../models/ticket-type-and-prices';

export const selectTicketTypesByOfferSetAndRate = createSelector(
  selectTicketTypes,
  (ticketTypes: TicketTypeAndPrices[]) => {
    const map: TicketTypesByOfferSetAndRate = new TicketTypesByOfferSetAndRate();

    ticketTypes.forEach((ticketType: TicketTypeAndPrices) => {
      if (ticketType.inventoryOfferSetId) {
        const offerSetId: string = ticketType.inventoryOfferSetId;

        ticketType.rates.forEach((rate) => {
          map.add(offerSetId, rate.id, ticketType.id);
        });
      }
    });

    return map;
  },
);

export const selectNonExclusiveTicketTypesByRate = createSelector(
  selectTicketTypes,
  (ticketTypes: TicketTypeAndPrices[]) => {
    const map: NonExclusiveTicketTypesByRate = {};

    ticketTypes.forEach((ticketType) => {
      if (!ticketType.inventoryExclusive) {
        ticketType.rates.forEach((rate) => {
          let ticketTypeIds = map[rate.id];

          if (!ticketTypeIds) {
            ticketTypeIds = [];
            map[rate.id] = ticketTypeIds;
          }

          ticketTypeIds.push(ticketType.id);
        });
      }
    });

    return map;
  },
);

export type NonExclusiveTicketTypesByRate = Record<string, string[]>;
