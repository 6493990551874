import { oidcUserStorageKey } from '../constants';
import { isSalesWebPage } from './isSalesWebPage';
import storageService from './storage/storageService';
export const getUserFromStorage = () => storageService.getItem(oidcUserStorageKey);
export const getAccessToken = () => {
    var _a;
    const token = (_a = getUserFromStorage()) === null || _a === void 0 ? void 0 : _a.access_token;
    if (!token && isSalesWebPage()) {
        const error = new Error('No access token available');
        console.error(error);
        throw error;
    }
    return token;
};
export const getUserClaims = () => {
    var _a, _b;
    const language = (_b = (_a = getUserFromStorage()) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.language;
    if (!language) {
        return null;
    }
    return { language };
};
