import { rawAdaSvg } from '@ticketmaster/tm1pos-web-shared/components/icons/IconAda';
import { rawIconCaution } from '@ticketmaster/tm1pos-web-shared/components/icons/IconCaution';
import { currencyFormatOptions } from '@ticketmaster/tm1pos-web-shared/constants';
import isMobile from 'ismobilejs';
import { uniqBy } from 'lodash';
import { ISM_ENV, STATUS } from '../../../../../../constants';
import messages, { getAdaTypeMessageDescriptor } from '../../../../messages';
import {
  SECTION_COLOR_INTERIOR_HOVER,
  SECTION_COLOR_OUTLINE_HOVER,
  SECTION_COLOR_SHAPE_STATUS_0,
  SECTION_COLOR_SHAPE_STATUS_1,
  SECTION_COLOR_SHAPE_STATUS_2,
  SECTION_COLOR_SHAPE_STATUS_3,
  SECTION_COLOR_SHAPE_STATUS_4,
  SECTION_COVER_PRESETS,
} from '../../constants';
import { convertToOptimizedColorId, getIsmPriceLevelColors } from './ism-colors';
import { setupTracingOnIceEvents } from './ism-tracing-events-listener';

// ICE initializations begins here.  First we call 'loadFeatures()' to load any capabilities you plan on using.
// Always include 'canvas', but the other items are plugins that add more capabilities to the map.
// this.loadFeatures(eventId,['canvas', 'selection', 'navigation', 'seat-attributes', 'tooltips']);
const loadFeatures = async (features) => {
  await window.ICE.loadFeatures(features);
};

const sectionCoversAlpha = (ice, value, isMobileMode) => {
  const defaultShape = ice.templates.find('default-shape');
  defaultShape.alpha = value;
  defaultShape.interiorColor = 'ffffff';

  const sectionTemplate = ice.templates.find('default-shape-text');
  sectionTemplate.interiorColor = '000000';
  sectionTemplate.alpha = 0.73;
  if (!isMobileMode) {
    const sectionTemplateHover = ice.templates.find('default-shape-hover-text');
    if (sectionTemplateHover) {
      sectionTemplateHover.interiorColor = '000000';
      sectionTemplateHover.alpha = 0.3;
    }
    const defaultPlaceTemplateHover = ice.templates.find('default-place-hover');
    if (defaultPlaceTemplateHover) {
      defaultPlaceTemplateHover.interiorColor = '666666';
      defaultPlaceTemplateHover.outlineColor = '111111';
    }
  }
};

export const checkForSegmentMatch = (gaSegments) => (acc, cur) => {
  let matchedSegments = gaSegments.filter(
    (item) => item.section && cur.segments?.find((segment) => segment.name === item.section),
  );
  if (cur.segments && cur.segments.length) {
    matchedSegments = cur.segments.reduce(checkForSegmentMatch(gaSegments), matchedSegments);
  }
  return [...acc, ...matchedSegments];
};

export const getPriceLevelFromGA = (matchedGAs) => {
  try {
    return matchedGAs[0].priceLevels[0] || '';
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const getFilteredStatusFromGA = (facet, priceLevelId, currentSection) => {
  try {
    if (facet[0].displayPriceRange) {
      return currentSection.filteredSection.filteredSection;
    }
    let filteredByPriceLevel;
    let filteredBySellClass;

    if (!facet[0].activePriceLevels || facet[0].activePriceLevels.length === 0) {
      filteredByPriceLevel = false;
    } else {
      const gaHasActivePriceLevel = facet[0].activePriceLevels.some((activeId) => activeId === priceLevelId);
      filteredByPriceLevel = !gaHasActivePriceLevel;
    }

    if (facet[0].activeSellClass) {
      const gaHasActiveSellClass = currentSection.places.some(
        (place) => place.sellClassName === facet[0].activeSellClass,
      );
      // covers the strange GA case with np place but avail only for open
      filteredBySellClass = !gaHasActiveSellClass && facet[0].activeSellClass !== STATUS.OPEN;
    } else {
      filteredBySellClass = true;
    }
    return filteredBySellClass || filteredByPriceLevel;
  } catch (e) {
    console.error(e);
  }
  return currentSection.filteredSection.filteredSection;
};

export const getPriceLevelFromChildPlaces = (places) => {
  try {
    return places.filter((p) => p.isOpen && p.colorId).pop().colorId;
  } catch (e) {
    return '';
  }
};

export const getColorId = (plId, priceLevels) => {
  try {
    const current = priceLevels.find((level) => level.id === plId);
    if (current.priceLevels && current.priceLevels.length) {
      return current.priceLevels[0];
    }
    return plId;
  } catch (e) {
    console.error(e);
    return plId;
  }
};
export const setupSegmentCovers = (segments, gaSegments = [], priceLevels = []) => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  segments.forEach((section) => {
    const sectionCover = section;
    if (sectionCover) {
      if (sectionCover.generalAdmission && sectionCover.openSection) {
        if (gaSegments.length) {
          let matchedGAs = [sectionCover].reduce(checkForSegmentMatch(gaSegments), []);
          matchedGAs = uniqBy(matchedGAs, 'section');
          if (matchedGAs && matchedGAs.length) {
            const priceLevel = getPriceLevelFromGA(matchedGAs);
            sectionCover.priceLevel = priceLevel;
            sectionCover.colorId = convertToOptimizedColorId(getColorId(priceLevel, priceLevels));
          } else if (sectionCover.places && sectionCover.places.length) {
            const childPriceLevel = getPriceLevelFromChildPlaces(sectionCover.places);
            sectionCover.priceLevel = childPriceLevel || '';
            sectionCover.colorId = convertToOptimizedColorId(childPriceLevel) || '';
          }
        } else if (sectionCover.places && sectionCover.places.length) {
          const childPriceLevel = getPriceLevelFromChildPlaces(sectionCover.places);
          sectionCover.priceLevel = childPriceLevel || '';
          sectionCover.colorId = convertToOptimizedColorId(childPriceLevel) || '';
        }
      }
      if (sectionCover.filteredSection && sectionCover.filteredSection.filteredSection) {
        sectionCover.shapeStatus = 0;
      } else if (sectionCover.openSection) {
        sectionCover.shapeStatus = Math.ceil(
          (sectionCover.openSection.totalNumber / sectionCover.totalPlaces) * SECTION_COVER_PRESETS,
        );
      }
    }
  });
  return segments;
};

export const setupPin = (pin, map, segments) => {
  pin.removeAllPins();
  map.reset();
  segments.forEach((segment) => {
    if (segment.sectionHaveBestAvailableSeats.ifBASection) {
      pin.addSectionPin(segment);
    }
  });
};

export const loadEvent = async (eventId, ice, map) => {
  await ice.loadData({ map: eventId });
  map.enableMouseInteraction();
  ice.startAnimation();
};

export const updatePlaceById = ({ place, props }) => {
  const p = place;
  if (p) {
    props.forEach(({ key, value }) => {
      p[key] = value;
    });
  }
};

export const updateGroupOfPlaces = ({ ice, group, props }) => {
  if (group && group.length) {
    group.forEach((item) => {
      const place = ice.data.getPlaceById(item);
      updatePlaceById({ place, props });
    });
  }
};
export const updateInCartStatus = ({ ice, inCart, removeInCart }) => {
  try {
    updateGroupOfPlaces({ ice, group: inCart, props: [{ key: 'inCart', value: true }] });
    updateGroupOfPlaces({ ice, group: removeInCart, props: [{ key: 'inCart', value: false }] });
  } catch (e) {
    console.error(`ICE Update In Cart Status Error: ${e}`); // eslint-disable-line no-console
  }
};

const isSellable = (status) => [STATUS.OPEN, STATUS.HOLD, STATUS.DIST_OPEN].includes(status);

export const formatPrice = (intl, price) => {
  if (!price) {
    return intl.formatMessage(messages.noPriceForSeat);
  }

  const { amount, currency } = price;
  return intl.formatNumber(amount, { ...currencyFormatOptions, currency });
};

const getAdaLabel = (intl, placeType) =>
  placeType?.name ? intl.formatMessage(getAdaTypeMessageDescriptor(placeType.name)) : undefined;

export const getPlaceStatusCachedExpansiveValues = ({ cache, colorId, intl, placeType, price, status }) => {
  const optimizedColorIdKey = `${colorId}`;
  let optimizedColorId = cache.get(optimizedColorIdKey);
  if (optimizedColorId === undefined) {
    optimizedColorId = convertToOptimizedColorId(colorId);
    cache.set(optimizedColorIdKey, optimizedColorId);
  }

  const priceFormattedKey = `${intl.locale}${price?.amount}${price?.currency}`;
  let priceFormatted = cache.get(priceFormattedKey);
  if (priceFormatted === undefined) {
    priceFormatted = formatPrice(intl, price);
    cache.set(priceFormattedKey, priceFormatted);
  }

  let adaLabel;
  if (getAdaTypeMessageDescriptor(placeType?.name) != null) {
    const adaLabelKey = placeType?.id?.toString();
    adaLabel = cache.get(adaLabelKey);
    if (adaLabel === undefined) {
      adaLabel = getAdaLabel(intl, placeType);
      cache.set(adaLabelKey, adaLabel);
    }
  }

  const isSellableStatusKey = `${status}`;
  let isSellableStatus = cache.get(isSellableStatusKey);
  if (isSellableStatus === undefined) {
    isSellableStatus = isSellable(status);
    cache.set(isSellableStatusKey, isSellableStatus);
  }

  return {
    adaLabel,
    isSellableStatus,
    optimizedColorId,
    priceFormatted,
  };
};

export const updatePlaceStatus = ({ ice, data, intl }) => {
  // Note: documentation for the fields used here can be found here: https://confluence.livenation.com/display/SDR/SeatStatus+Selectors+VS+ISM
  try {
    if (!data || data.length < 1) {
      return;
    }

    const cache = new Map();
    const dataLength = data.length;
    // Use of a `for` is slightly faster than `forEach()`
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < dataLength; i++) {
      const {
        id,
        status,
        rating,
        rowId,
        sectionId,
        price,
        ticketTypeDescription,
        sellClassName,
        priceId,
        colorId,
        className,
        filtered,
        isOpen,
        isBestAvailable,
        accessibility,
        isAccessible,
        placeType,
        showCompanionWarning,
        generalAdmission,
      } = data[i];

      const place = ice.data.getPlaceById(id);

      if (place) {
        const { adaLabel, isSellableStatus, optimizedColorId, priceFormatted } = getPlaceStatusCachedExpansiveValues({
          cache,
          colorId,
          intl,
          placeType,
          price,
          status,
        });

        // This is incredibly slow, but we can't change it since we need to modify the object in place...
        //   If Ice would track changes to the Map (ice.data.placesById), we'd cut the process time by 2.5x
        Object.assign(place, {
          ticketTypeStatus: status,
          className,
          filtered,
          priceId,
          colorId: optimizedColorId,
          isBestAvailable,
          isOpen,
          price: priceFormatted,
          rowId,
          rating,
          sectionId,
          ticketTypeDescription,
          accessibility,
          isAccessible,
          placeType,
          adaLabel,
          sellClass: sellClassName,
          showCompanionWarning,
          isSellable: isSellableStatus,
          generalAdmission,
        });
      }
    }
  } catch (e) {
    console.error(`ICE Update Place Status Error: ${e}`); // eslint-disable-line no-console
  }
};

export const setupPriceLevelSelection = ({ ice }) => {
  try {
    getIsmPriceLevelColors().forEach(({ color, colorId }) => {
      ice.templates.add(
        window.ICE.Template.create({ interiorColor: color, clickEvents: true }).ByPlace({
          isSellable: true,
          filtered: false,
          colorId,
          selected: false,
        }),
      );
    });
  } catch (e) {
    console.error('ERROR configuring price level place colors on map:', e);
  }
};

/**
 * To Setup the selection plugin simply create it and add it to ice.  From there it is just a matter of
 * setting up color templates for the various "select, preselect" states.
 * */
export const setupSelectionPlugin = ({ ice, map, id, rangeMode }) => {
  // Add some simple coloring for selections and pre-selections
  const ICE = window.ICE;

  // paint seats with color depending on priceLevel
  if (rangeMode === false) {
    setupPriceLevelSelection({ ice });
  }

  ICE.Selection.create(ice, map, { buttonDiv: id });

  ICE.settings.spriteDefinitions.addSpriteSheetDefinition('/static/ism', 'ism_icons.json');
  const adaIcon = ICE.Template.create().ByPlace({ isAccessible: true }).Icon('ada-icon.png', 'ada-place');

  ice.templates.add(adaIcon);

  const placeOptions = {
    selected: true,
  };
  const configSelected = { interiorColor: 'ffffff', outlineColor: 'ffffff' };
  const selectedIcon = ICE.Template.create(configSelected)
    .ByPlace(placeOptions)
    .Icon('img-seat-checkmark.png', 'selected-place');
  const selectedHoverIcon = ICE.Template.create(configSelected)
    .ByPlace(placeOptions)
    .Icon('img-seat-checkmark-hover.png', 'selected-place-hover')
    .Hover();
  const inCart = { inCart: true };
  const selectedInCartIcon = ICE.Template.create(configSelected)
    .ByPlace(inCart)
    .Icon('img-seat-checkmark.png', 'selected-incart-place');
  const selectedInCartHoverIcon = ICE.Template.create(configSelected)
    .ByPlace(inCart)
    .Icon('img-seat-checkmark-hover.png', 'selected-incart-place-hover')
    .Hover();

  ice.templates.add(selectedIcon);
  ice.templates.add(selectedHoverIcon);

  ice.templates.add(selectedInCartIcon);
  ice.templates.add(selectedInCartHoverIcon);
};
export const setupPriceRangeSectionCovers = ({ ice }) => {
  ice.templates.add(
    window.ICE.Template.create({ interiorColor: SECTION_COLOR_SHAPE_STATUS_0, alpha: 0.4 })
      .ByComposite({ shapeStatus: 0 })
      .Shape(),
  );
  ice.templates.add(
    window.ICE.Template.create({ interiorColor: SECTION_COLOR_SHAPE_STATUS_1, alpha: 0.4 })
      .ByComposite({ shapeStatus: 1 })
      .Shape(),
  );
  ice.templates.add(
    window.ICE.Template.create({ interiorColor: SECTION_COLOR_SHAPE_STATUS_2, alpha: 0.4 })
      .ByComposite({ shapeStatus: 2 })
      .Shape(),
  );
  ice.templates.add(
    window.ICE.Template.create({ interiorColor: SECTION_COLOR_SHAPE_STATUS_3, alpha: 0.4 })
      .ByComposite({ shapeStatus: 3 })
      .Shape(),
  );
  ice.templates.add(
    window.ICE.Template.create({ interiorColor: SECTION_COLOR_SHAPE_STATUS_4, alpha: 0.4 })
      .ByComposite({ shapeStatus: 4 })
      .Shape(),
  );
};

export const setupPriceLevelSectionCovers = ({ ice }) => {
  try {
    getIsmPriceLevelColors().forEach(({ color, colorId }) => {
      ice.templates.add(window.ICE.Template.create({ interiorColor: color }).ByComposite({ colorId }).Shape());
    });
    ice.templates.add(
      window.ICE.Template.create({ interiorColor: SECTION_COLOR_INTERIOR_HOVER })
        .ByComposite({ shapeStatus: 0 })
        .Shape(),
    );
  } catch (e) {
    console.error('ERROR configuring price level section colors on map:', e);
  }
};

export const setupSectionCovers = ({ ice, rangeMode }) => {
  const ICE = window.ICE;
  /**
   * The shapeStatus : 0 - 1 - 2 - 3 - 4 imply the seats availability in percentage on the ISM map
   * based on the availability, the seat cover (colors) to the sections are applied
   * 0 - 0%
   * 1- 0 - 25%
   * 2- 25 - 50%
   * 3- 50 - 75%
   * 4 - 75% and above
   */

  try {
    if (rangeMode) {
      setupPriceRangeSectionCovers({ ice });
    } else if (rangeMode === false) {
      setupPriceLevelSectionCovers({ ice });
    }
    const gaHover = ICE.Template.create({
      interiorColor: SECTION_COLOR_INTERIOR_HOVER,
      outlineColor: SECTION_COLOR_OUTLINE_HOVER,
      alpha: 0,
    })
      .ByAncestor()
      .Shape()
      .Hover();
    ice.templates.add(gaHover);
    const gaHoverText = ice.templates.find('default-ga-shape-hover-text');
    gaHoverText.interiorColor = '026CDF';
  } catch (e) {
    console.log(e); // eslint-disable-line no-console
  }
};
/**
 * To Setup the navigation plugin (+, -, Home Zoom UI), simply create it and add it to ice.
 * */
export const setupNavPlugin = ({ ice, map, id }) => {
  window.ICE.Navigation.create(ice, map, { div: id });
};

const aggregateTemplate = {
  bestAvailable: {
    ifBASection: false,
  },
  openSection: {
    totalNumber: 0,
  },
  filtered: {
    filteredSection: true,
  },
};
const generateWhere = {
  bestAvailable: `
    const { isBestAvailable } = place;
    if(isBestAvailable === true) {
        aggregate.ifBASection = true;
    }
`,
  openSection: `
  const { openSection } = place;
  if(place.isOpen && !place.filtered){
    aggregate.totalNumber++
  }
`,
  filtered: `
    const { filtered } = place;
    if(filtered === false) {
        aggregate.filteredSection = false;
    }
`,
};

export const setupAggregateData = ({ ice }) => {
  ice.data.aggregate.addSectionAggregate('openSection', generateWhere.openSection, aggregateTemplate.openSection);
  ice.data.aggregate.addSectionAggregate('filteredSection', generateWhere.filtered, aggregateTemplate.filtered);
  ice.data.aggregate.addSectionAggregate(
    'sectionHaveBestAvailableSeats',
    generateWhere.bestAvailable,
    aggregateTemplate.bestAvailable,
  );
};

export const setupTooltipsPlugin =
  (intl) =>
  ({ ice, map }) => {
    // create tooltip plugin
    window.ICE.Tooltips.create(ice, map, {
      type: window.ICE.Tooltips.TYPE.NONINTERACTIVE,
      allowSectionTooltipOnCursor: true,
      delay: 100,
    });
    const companionWarning = `
    <div class="tool-tip-data-item__tt tool-tip-data-item__tt">
      ${rawIconCaution}
    </div>
    <div class="tool-tip-data-item__tt tool-tip-data-item__tt">
      ${intl.formatMessage(messages.iceCompanionWarning)}
    </div>
   `;
    const temp = `
    <div class="tool-tip-data-item tool-tip-data-item__place">
      <div class="tool-tip-data-item__tt">
        {{place.ticketTypeDescription}} {{#if place.sellClass}}({{place.sellClass}}){{/if}}
      </div>
      <div class="{{place.className}}">{{place.price}}</div>
    </div>
    {{#if place.adaLabel}}
      <div class="tool-tip-data-item tool-tip-data-item__place">
        <div class="tool-tip-data-item__tt tool-tip-data-item__tt-ada">${rawAdaSvg}{{place.adaLabel}}</div>
      </div>
      {{#if place.showCompanionWarning}}
        <div class="tool-tip-data-item tool-tip-data-item__place tool-tip-data-item__place--warning">${companionWarning}</div>
      {{/if}}
    {{/if}}
    `;

    const templateHeaderSection = intl.formatMessage(messages.icePlaceTemplateHeaderSection, {
      name: '<br /><span class="ism-map-tooltip__header-cell-value">{{place.parent.parent.name}}</span>',
    });
    const templateHeaderRow = intl.formatMessage(messages.icePlaceTemplateHeaderRow, {
      name: '<br /><span class="ism-map-tooltip__header-cell-value">{{place.parent.name}}</span></span>',
    });
    const templateHeaderSeat = intl.formatMessage(messages.icePlaceTemplateHeaderSeat, {
      name: '<br /><span class="ism-map-tooltip__header-cell-value">{{place.name}}</span>',
    });
    const template = `
    <div class="ism-map-tooltip__header">
      <div class="ism-map-tooltip__header-cell">
        ${templateHeaderSection}
      </div>
      <div class="ism-map-tooltip__header-cell ism-map-tooltip__header-cell--center">
        ${templateHeaderRow}
      </div>
      <div class="ism-map-tooltip__header-cell ism-map-tooltip__header-cell--right">
        ${templateHeaderSeat}
      </div>
    </div>
    {{#if place.isOpen}}
      ${temp}
    {{else place.selected}}
      ${temp}
    {{else place.inCart}}
      ${temp}
    {{/if}}
    `;

    ice.execute('tooltip-place-template', template);

    const sectionTooltipHeader = intl.formatMessage(messages.iceSection, {
      sectionName: '{{section.name}}',
    });
    const availableSeats = intl.formatMessage(messages.iceAvailableSeats, {
      available: '{{section.openSection.totalNumber}}',
      total: '{{section.totalPlaces}}',
    });
    const sectionTemplate = `
    <div class="ism-map-tooltip__header">
      ${sectionTooltipHeader}
    </div>
    <div class="tool-tip-data-item tool-tip-data-item__available">
      ${availableSeats}
    </div>
    `;
    const GASectionTemplate = `
    <div class="ism-map-tooltip__header">
      {{section.name}}
    </div>
    <div class="tool-tip-data-item tool-tip-data-item__available">
      ${availableSeats}
    </div>
    `;
    const sectionTP = `
    <div>
        {{#if section.generalAdmission}}
            ${GASectionTemplate}
        {{else}}
            ${sectionTemplate}
        {{/if}}
    </div>
    `;
    ice.execute('tooltip-section-template', sectionTP);
  };

const initialize = async (mapId, featuresCollection, rangeMode, storeDispatch) => {
  try {
    const isMobileMode = isMobile().any;
    const features = featuresCollection.map((item) => item.name);
    await loadFeatures(['canvas', ...features]);
    const ice = window.ICE.create({
      mobile: isMobileMode,
      applicationName: 'Salesdeck Responsive',
      env: ISM_ENV,
    });

    ice.on('error', (err) => {
      console.error(`ICE Error:`, err); // eslint-disable-line no-console
    });
    setupTracingOnIceEvents(ice, storeDispatch);

    // Create the map and plugins
    const map = window.ICE.Views.Map.create(mapId, window.ICE.CanvasRenderer.Map);
    const pin = window.ICE.Pin.create(map);
    ice.addView(map);

    // Initialize the plugins
    featuresCollection.forEach((item) => {
      if (item.fn && typeof item.fn === 'function') {
        item.fn({ ice, map, ...item.params, rangeMode });
      }
    });

    setupAggregateData({ ice });
    // Make the section covers semi-transparent.
    sectionCoversAlpha(ice, 0.5, isMobileMode);
    setupSectionCovers({ ice, rangeMode });
    const template = ice.templates.find('default-spyglass');
    template.interiorColor = '1e262c';
    template.alpha = 0.7;

    window.ice = ice;

    return { ice, map, pin };
  } catch (err) {
    console.error(`ICE Initialization Error`, err); // eslint-disable-line no-console
  }
  return null;
};

export default initialize;
