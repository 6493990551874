import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { v4 as uuidV4 } from 'uuid';
import { Tm1Option, Tm1Select, Tm1Typography } from '../../../../components/v2';
import { getPrinterDriverFromMethodAndDpi, getPrinterDrivers, getPrintingMethodAndDpi } from '../../../PrinterDriver';
import messages, { getLabelForPrinterDriver } from '../../../printing-message';
import '../EditPrinterConfiguration.scss';
const drivers = getPrinterDrivers();
export const DropdownPrinterDrivers = ({ defaultDriver, disabled, intl, onChange, readOnly, showValidationMessage, }) => {
    const [selectedDriver, setSelectedDriver] = useState();
    const [uniqueId] = useState(() => uuidV4());
    useEffect(() => {
        if (defaultDriver) {
            const driverFromMethodAndDpi = getPrinterDriverFromMethodAndDpi(defaultDriver);
            if (!driverFromMethodAndDpi) {
                setSelectedDriver(undefined);
            }
            else {
                setSelectedDriver(driverFromMethodAndDpi);
            }
        }
    }, [defaultDriver, onChange, setSelectedDriver, selectedDriver]);
    const onPrinterDriverChange = (event) => {
        const newValue = (event.target.value || '').trim() || undefined;
        if (newValue) {
            const isDriverSupported = drivers.includes(newValue);
            setSelectedDriver(isDriverSupported ? newValue : undefined);
            onChange(isDriverSupported ? getPrintingMethodAndDpi(newValue) : null);
        }
    };
    const selectedDriversLabel = selectedDriver ? intl.formatMessage(getLabelForPrinterDriver(selectedDriver)) : '';
    return (_jsxs("div", { className: "dropdown-printer-driver", children: [_jsx("label", { className: "dropdown-printer-driver__label", htmlFor: uniqueId, children: _jsx(Tm1Typography, { type: "label-helpertext-regular", children: intl.formatMessage(messages.printerDriverLabel) }) }), readOnly ? (_jsx(Tm1Typography, { children: selectedDriversLabel })) : (_jsx(Tm1Select, { disabled: disabled, error: showValidationMessage && !selectedDriver ? intl.formatMessage(messages.aSelectionIsRequired) : '', id: uniqueId, onChange: onPrinterDriverChange, placeholder: intl.formatMessage(messages.printerDriverPlaceholder), value: selectedDriver, size: 6, children: drivers.map((driver) => {
                    const label = getLabelForPrinterDriver(driver);
                    return (_jsx(Tm1Option, { value: driver, children: intl.formatMessage(label) }, driver));
                }) }))] }));
};
export default injectIntl(DropdownPrinterDrivers);
