/* eslint-disable react/jsx-no-useless-fragment */

import LoadingComponent from '@ticketmaster/tm1pos-web-shared/components/LoadingComponent';
import { LogComponent, LogStatus } from '@ticketmaster/tm1pos-web-shared/errorHandling/constants';
import { toLogError } from '@ticketmaster/tm1pos-web-shared/services/errors/error-handler';
import { logClientToCloud } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { useLayoutEffect, useState } from 'react';
import { installImportMap } from '../../services/tm1Api/install-importmap';
import { RegisterEventListener } from '../../services/tm1Api/register-event-listener';
import { useAppDispatch } from '../../store/hooks';
import type { PropsWithChildren } from 'react';

export type Tm1BootstrapProps = PropsWithChildren<unknown>;

export const Tm1Bootstrap = ({ children }: Tm1BootstrapProps) => {
  const dispatch = useAppDispatch();

  const [isTm1Bootstrapped, setIsTm1Bootstrapped] = useState(false);

  const tryAndLogOnError = async (callee: () => Promise<void>, errorMessage: string) => {
    try {
      await callee();
    } catch (e: any) {
      console.error('Could not bootstrap TM1 dependencies', e);
      dispatch(
        logClientToCloud({
          data: {
            error: {
              ...toLogError(e),
              message: errorMessage,
            },
          },
          type: LogComponent.APP,
          status: LogStatus.ERROR,
        }),
      );
    }
  };

  useLayoutEffect(() => {
    Promise.all([
      tryAndLogOnError(installImportMap, 'Tm1ImportMapLoader: Could not load TM1 ImportMap'),
      tryAndLogOnError(async () => {
        const rei = RegisterEventListener.getInstance();
        await rei.initialize(document);
        if (rei.hasInitError()) {
          throw rei.initError;
        }
      }, 'Tm1Bootstrap: Could not initialize.'),
    ]).then(() => {
      setIsTm1Bootstrapped(true);
    });
  }, []);

  if (isTm1Bootstrapped) {
    return <>{children}</>;
  }

  return <LoadingComponent largeGear />;
};
