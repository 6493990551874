import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getPrintableTestTicket } from '../services/sales-api-client/sales-api-print-test-ticket';
import { persistLocalForageUtils } from '../utils/storage/persistLocalForageUtils';
import { PrinterService } from './PrinterService';
import { selectPrinterError } from './printingModule-bridge';
import { selectActivePrinter, selectHasQzTrayEnabled, selectHasTmdsPrintingEnabled, selectPrintingModuleEnabled, } from './printingModule-selectors';
import { printTestTicket, printTickets, refreshPrinters, reloadActivePrinter, resetPrinterError, setActivePrinter, setPrinters, setPrinterServiceRefreshed, setPrintingModuleInitialized, setPrintTestTicketState, } from './printingModule-slice';
import qzPrintingModule from './QzPrintingModule';
import tmdsPrintingModule from './TmdsPrintingModule';
export function* initialize() {
    const activePrinter = yield call(getPersistedActivePrinter);
    const isTmdsOnly = yield call(isTmdsTheOnlyAvailablePrintingService);
    yield call(tmdsPrintingModule.initialize, activePrinter, isTmdsOnly);
    yield call(qzPrintingModule.initialize, activePrinter, false);
    yield put(setPrintingModuleInitialized(true));
}
export function* getPersistedActivePrinter() {
    const activePrinter = yield persistLocalForageUtils.getItem('activePrinter');
    return activePrinter;
}
export function* isTmdsTheOnlyAvailablePrintingService() {
    let isOnlyServiceAvailable = false;
    const isPrintingConfigurationEnabled = yield select(selectPrintingModuleEnabled);
    if (isPrintingConfigurationEnabled) {
        const isQzTrayEnabled = yield select(selectHasQzTrayEnabled);
        const isTmdsEnabled = yield select(selectHasTmdsPrintingEnabled);
        if (!isQzTrayEnabled && isTmdsEnabled) {
            isOnlyServiceAvailable = true;
        }
    }
    return isOnlyServiceAvailable;
}
function* watchReloadActivePrinter() {
    yield takeLatest(reloadActivePrinter.type, doReloadActivePrinter);
}
export function* doReloadActivePrinter() {
    const activePrinter = yield call(getPersistedActivePrinter);
    yield put(setActivePrinter(activePrinter));
    if (activePrinter) {
        yield call(doRefreshPrinters, {
            payload: { printerService: activePrinter.printerService, remoteHost: activePrinter.remoteHost },
            type: refreshPrinters.type,
        });
    }
}
export function* print(filesToPrint, printer) {
    if (filesToPrint.length === 0) {
        return;
    }
    const printingModuleEnabled = yield select(selectPrintingModuleEnabled);
    if (printingModuleEnabled) {
        yield put(resetPrinterError());
        const printerToUse = printer || (yield select(selectActivePrinter));
        yield call(qzPrintingModule.print, printerToUse, filesToPrint);
        yield call(tmdsPrintingModule.print, printerToUse, filesToPrint);
    }
    else {
        yield call(TmdsClient.print, filesToPrint);
    }
}
function* watchRefreshPrinter() {
    yield takeLatest(refreshPrinters.type, doRefreshPrinters);
}
export function* doRefreshPrinters(action) {
    yield put(setPrinters([]));
    switch (action.payload.printerService) {
        case PrinterService.Tmds:
            yield call(tmdsPrintingModule.refreshPrinters);
            break;
        case PrinterService.QzTrayLocal:
        case PrinterService.QzTrayRemote:
            yield call(qzPrintingModule.refreshPrinters, action.payload.remoteHost);
            break;
        default:
            throw new Error(`printer service unknown: ${action.payload.printerService}`);
    }
    yield put(setPrinterServiceRefreshed(true));
}
export function* doPrintTestTicket(action) {
    const printableTestTicket = yield call(getPrintableTestTicket, {
        printingMethod: action.payload.method,
        printingDpi: action.payload.dpi,
    });
    yield call(print, printableTestTicket, action.payload);
    const printerError = yield select(selectPrinterError);
    yield put(setPrintTestTicketState(printerError ? 'FAILURE' : 'SUCCESS'));
}
function* watchPrintTestTicket() {
    yield takeLatest(printTestTicket.type, doPrintTestTicket);
}
export function* doPrintTickets(action) {
    yield call(print, action.payload.files, action.payload.printer);
}
function* watchPrint() {
    yield takeLatest(printTickets.type, doPrintTickets);
}
export default [watchRefreshPrinter, watchReloadActivePrinter, watchPrintTestTicket, watchPrint];
