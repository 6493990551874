import TmdsClient from '@ticketmaster/tm1pos-tmds-client';
import { OPEN_ERROR_MODAL } from '@ticketmaster/tm1pos-web-shared/errorHandling/actions';
import { featureFlagsLoaded } from '@ticketmaster/tm1pos-web-shared/feature-flags';
import { clearEmvTransaction } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import { initializePrintModule, print } from '@ticketmaster/tm1pos-web-shared/printing';
import { selectPrintingModuleEnabled } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { initializePrintingModule } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-slice';
import { logToCloud } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api-system';
import { unhandledErrorCaught } from '@ticketmaster/tm1pos-web-shared/store/actions';
import {
  FINISHED_USER_DATA_RETRIEVAL,
  JWT_TOKEN_EXPIRED,
  START_USER_DATA_RETRIEVAL,
  UPDATE_USER_TRACKING_INFORMATION,
} from '@ticketmaster/tm1pos-web-shared/store/actions-constants';
import { getEventsData, loadInitialEvents } from '@ticketmaster/tm1pos-web-shared/store/events/sagas';
import { selectUserInformation } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { startFetchingUserInformation } from '@ticketmaster/tm1pos-web-shared/store/user-information/user-information.sagas';
import {
  fetchUserInformationFailed,
  fetchUserInformationSuccess,
} from '@ticketmaster/tm1pos-web-shared/store/user-information/user.slice';
import {
  DEFAULT_LANGUAGE,
  loadMessages,
  selectLanguage,
  setLanguage,
  WEB_NAMESPACE_TRANSIFEX,
} from '@ticketmaster/tm1pos-web-shared/translations';
import { getAccessToken, getUserClaims, getUserFromStorage } from '@ticketmaster/tm1pos-web-shared/utils/oauth-utils';
import { delay } from '@ticketmaster/tm1pos-web-shared/utils/sagas-utils';
import { localForageUtils } from '@ticketmaster/tm1pos-web-shared/utils/storage/localForageUtils';
import storage from '@ticketmaster/tm1pos-web-shared/utils/storage/storageService';
import { push } from 'connected-react-router';
import { buffers } from 'redux-saga';
import { actionChannel, call, flush, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  COMPONENT_ID,
  FEEDBACK_CONTACTS_URL,
  FEEDBACK_SUBMIT_URL,
  TM1SALES_APP_NAME,
  TRANSLATIONS_URL,
  userOauth,
} from '../../constants';
import { askDeleteCartContent } from '../../middleware/errorsHandling/errorModalContentConfig';
import { checkoutToggleLocation } from '../../middleware/mixpanel-constants';
import { getCertificate } from '../../services/sales-api-client/sales-api-certificate';
import { cancelOrder, emptyCart, unReserveCartItem } from '../../services/sales-api-client/sales-api-shopping';
import { callUserTracking } from '../../services/sales-api-client/sales-api-system';
import userManager from '../../utils/userManager';
import { DO_DELIVERY_END } from '../Checkout/components/Form/actions-constants';
import {
  FETCH_SEARCH_PRINTER,
  PLACE_TO_RESERVE_DESELECT,
  PLACE_TO_UNRESERVE_FETCH,
  SEARCH_PRINTER,
} from '../EventDetailPage/constants';
import {
  selectCurrentEventDetails,
  selectDefaultInventoryType,
  selectFormattedReservedItems,
  selectOrderIdToRetry,
  selectSelectedInventoryType,
} from '../EventDetailPage/selectors/main';
import { selectCompoundEventId } from '../EventDetailPage/selectors/selectEventIds';
import { getFeedbackContacts, toggleCheckout, userSignout } from './actions';
import {
  CLEAR_CART,
  CLEAR_CART_FAILED,
  CLEAR_CART_SUCCESS,
  CLEAR_ORDER_DELETE,
  CLIENT_CLOUD_LOG,
  FINISHED_LOCALE_RETRIEVAL,
  FINISHED_PAYMENT_CERTIFICATE_RETRIEVAL,
  GET_FEEDBACK_CONTACTS,
  PAYMENT_CERTIFICATE_FAILED,
  PAYMENT_CERTIFICATE_SUCCESS,
  PRINTER_DROPDOWN_VISIBLE_ONLINE_CHECK,
  PRINTER_DROPDOWN_VISIBLE_ONLINE_POLLING_CHECK,
  PROMPT_ORDER_DELETE,
  REJECT_ORDER_DELETE,
  START_LOGIN,
  START_ORDER_DELETE,
  SUBMIT_FEEDBACK,
  TMDS_STATUS_CHECK,
  USER_TIMEOUT,
} from './actions-constants';
import {
  selectCartId,
  selectCartItemId,
  selectPrinterDropdownStatus,
  selectRowCart,
  selectShowCart,
} from './selectors';

const APPLICATION_JSON = 'application/json';

export function* clearByOrderId() {
  try {
    const orderId = yield select(selectOrderIdToRetry);
    const cartId = yield select(selectCartId);
    const currentEventDetails = yield select(selectCurrentEventDetails);
    const hostName = currentEventDetails?.hostName;
    const defaultSellClass = yield select(selectDefaultInventoryType);
    const sellClass = yield select(selectSelectedInventoryType);
    const showCart = yield select(selectShowCart);

    const { errorMessage, errors } = yield call(cancelOrder, {
      orderId,
      cartId,
      hostName,
      defaultSellClass,
      sellClass,
    });

    yield put(toggleCheckout({ location: checkoutToggleLocation.NAV, showCart }));
    yield put({ type: CLEAR_ORDER_DELETE });
    if (errorMessage || errors.length > 0) {
      throw new Error(errorMessage || errors[0].message);
    } else {
      yield put({ type: CLEAR_CART_SUCCESS });
    }
  } catch (err) {
    yield put({ type: CLEAR_CART_FAILED, err });
  } finally {
    yield put(clearEmvTransaction());
  }
}

export function* handlePromptOrderDelete() {
  const orderId = yield select(selectOrderIdToRetry);
  yield put({
    type: OPEN_ERROR_MODAL,
    content: askDeleteCartContent(orderId),
  });
  const { approve } = yield race({
    reject: take(REJECT_ORDER_DELETE),
    approve: take(START_ORDER_DELETE),
  });
  if (approve) {
    yield call(clearByOrderId);
  }
}

export function* clearCart(action = {}) {
  try {
    let { cartId } = action;

    if (!cartId) {
      cartId = yield select(selectCartId);
    }
    if (!cartId) {
      yield put({ type: action.success || CLEAR_CART_SUCCESS });
      return;
    }

    const { errorMessage, errors } = yield call(emptyCart, cartId);

    if (errorMessage || errors.length > 0) {
      throw new Error(errorMessage);
    } else {
      yield put({ type: action.success || CLEAR_CART_SUCCESS });
    }
  } catch (err) {
    yield put({
      type: action.error || CLEAR_CART_FAILED,
      err,
    });
  } finally {
    yield put(clearEmvTransaction());
  }
}

export function* getInitialEvents() {
  yield call(getEventsData, loadInitialEvents());
}

export function* unReserveSingleSeat() {
  try {
    const cartItem = yield select(selectCartItemId);
    const cart = yield select(selectRowCart);
    const currentEventDetails = yield select(selectCurrentEventDetails);
    const hostName = currentEventDetails?.hostName;
    const eventId = yield select(selectCompoundEventId);
    const { errorMessage, errors, data } = yield call(unReserveCartItem, { eventId, cart, cartItem, hostName });

    if (errorMessage || errors.length > 0) {
      throw new Error(errorMessage);
    } else if (!data.unreserve) {
      yield put({ type: CLEAR_CART_SUCCESS });
    } else {
      const modifiedTickets = yield select(selectFormattedReservedItems(data.unreserve.items, false));
      const rowData = { ...data.unreserve };
      const modifiedData = {
        ...data.unreserve,
        items: modifiedTickets,
      };
      yield put({
        type: PLACE_TO_UNRESERVE_FETCH.SUCCESS,
        rowCart: rowData,
        data: modifiedData,
      });
    }
  } catch (err) {
    yield put({ type: PLACE_TO_UNRESERVE_FETCH.ERROR, err });
  }
}

export function* fetchPaymentCertificate() {
  const certificateResponse = yield call(getCertificate);
  return certificateResponse.data.certificate;
}

export function* startFetchingAdditionalUserData() {
  const userInformation = yield select(selectUserInformation);

  yield put(getFeedbackContacts());
  yield put({
    type: UPDATE_USER_TRACKING_INFORMATION,
    userTrackingInformation: userInformation.profile,
  });

  yield put({ type: FINISHED_USER_DATA_RETRIEVAL });

  localForageUtils.clearDb();
}

export function* criticalErrorCaughtHandler() {
  yield put(push('/error'));
}

export function* startFetchingPaymentCertificate() {
  try {
    const certObj = yield call(fetchPaymentCertificate);
    yield put({ type: PAYMENT_CERTIFICATE_SUCCESS, certificate: certObj });
  } catch (error) {
    yield put({ type: PAYMENT_CERTIFICATE_FAILED });
  }

  yield put({ type: FINISHED_PAYMENT_CERTIFICATE_RETRIEVAL });
}

export function* startUserTracking() {
  try {
    yield call(callUserTracking);
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
  }
}

export function* handleFeatureFlagsComplete() {
  const printingModuleEnabled = yield select(selectPrintingModuleEnabled);
  if (!printingModuleEnabled) {
    yield put({ type: SEARCH_PRINTER });
  }
}

export function* startFetchingLocale() {
  let locale;
  try {
    locale = getUserClaims().language || DEFAULT_LANGUAGE;
    locale = Intl.getCanonicalLocales(locale)[0];
  } catch (err) {
    locale = DEFAULT_LANGUAGE;
  }

  yield put(setLanguage(locale));
  yield put({ type: FINISHED_LOCALE_RETRIEVAL });
}

export function* watchStartFetchingUserInformation() {
  yield takeEvery(START_USER_DATA_RETRIEVAL, startFetchingUserInformation, TM1SALES_APP_NAME, true);
  yield takeEvery(START_USER_DATA_RETRIEVAL, startFetchingLocale);
}

export function* watchStartFetchingEvents() {
  yield takeEvery(fetchUserInformationSuccess.type, getInitialEvents);
}

export function* startRetrievingTranslationFiles() {
  const language = yield select(selectLanguage);
  yield put(
    loadMessages({
      url: TRANSLATIONS_URL,
      language,
      namespace: WEB_NAMESPACE_TRANSIFEX,
    }),
  );
}

export function* watchFetchAdditionalUserData() {
  yield takeEvery(fetchUserInformationSuccess.type, startFetchingAdditionalUserData);
}

export function* watchCriticalError() {
  yield takeEvery(unhandledErrorCaught.type, criticalErrorCaughtHandler);
  yield takeEvery(fetchUserInformationFailed.type, criticalErrorCaughtHandler);
}

export function* watchFinishFetchUserData() {
  yield takeEvery(FINISHED_USER_DATA_RETRIEVAL, startUserTracking);
  yield takeEvery(FINISHED_USER_DATA_RETRIEVAL, startFetchingPaymentCertificate);
}

export function* watchSetupFeatureFlagsCompleted() {
  yield takeEvery(featureFlagsLoaded.type, handleFeatureFlagsComplete);
}

export function* watchLocaleRetrievalCompleted() {
  yield takeEvery(FINISHED_LOCALE_RETRIEVAL, startRetrievingTranslationFiles);
}

export function* oidcLogin() {
  yield userManager.signinRedirect();
}

export function* watchLogin() {
  yield takeEvery(START_LOGIN, oidcLogin);
}

export function* oidcLogout() {
  const cartId = yield select(selectCartId);
  if (cartId) {
    yield put({ type: CLEAR_CART, trigger: 'OIDC logout' });
    yield take(CLEAR_CART_SUCCESS);
    yield put(clearEmvTransaction());
  }

  const expiringUser = yield getUserFromStorage();
  yield userManager.removeUser();

  yield storage.clearStorage();
  yield localForageUtils.clearDb();

  if (expiringUser) {
    window.location.replace(userOauth.getEndSessionEndpoint(expiringUser));
  } else {
    userManager.clearStaleState();
    userManager.signinRedirect();
  }
}

export function* fetchFeedbackContacts(action) {
  try {
    const accessToken = getAccessToken();

    const response = yield fetch(FEEDBACK_CONTACTS_URL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      yield put({ type: action.ERROR });
      return;
    }

    const feedbackContacts = yield response.json();

    yield put({
      type: action.SUCCESS,
      feedbackContacts: {
        email: feedbackContacts?.email ?? '',
        phone: feedbackContacts?.phone ?? '',
        link: feedbackContacts?.page ?? '',
      },
    });
  } catch (e) {
    yield put({ type: action.ERROR });
  }
}

export function* submitFeedback(action) {
  try {
    const accessToken = getAccessToken();

    yield put({ type: action.LOADING });

    const response = yield fetch(FEEDBACK_SUBMIT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': APPLICATION_JSON,
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        message: `${action.feedbackText}`,
        containerType: 'sales',
        componentIds: [COMPONENT_ID],
      }),
    });

    if (!response.ok) {
      yield put({ type: action.ERROR });
    } else {
      yield put({ type: action.SUCCESS });
    }
  } catch (e) {
    yield put({ type: action.ERROR });
  }
}

export function urlencode(str) {
  return escape(str)
    .replace(/\+/g, '%2B')
    .replace(/%20/g, '+')
    .replace(/\*/g, '%2A')
    .replace(/\//g, '%2F')
    .replace(/@/g, '%40');
}

export function* checkPrinterDropdownStatus() {
  const buffer = buffers.sliding(7);
  const chan = yield actionChannel(
    [PRINTER_DROPDOWN_VISIBLE_ONLINE_CHECK, PRINTER_DROPDOWN_VISIBLE_ONLINE_POLLING_CHECK],
    buffer,
  );
  try {
    while (chan) {
      yield take(chan);
      const printerDropDownDetails = yield select(selectPrinterDropdownStatus(TmdsClient));
      const { selectedPrinterData: selectedPrinter } = TmdsClient;
      yield put({ type: SEARCH_PRINTER });
      if (selectedPrinter && selectedPrinter.hasError && printerDropDownDetails.isOnScreen && buffer.isEmpty()) {
        yield delay(5000);
        yield put({ type: PRINTER_DROPDOWN_VISIBLE_ONLINE_POLLING_CHECK });
      }
    }
  } catch (e) {
    console.warn('tmds dropdown printing status error ', e); // eslint-disable-line no-console
  } finally {
    yield flush(chan);
    yield delay(1000);
    yield put({ type: PRINTER_DROPDOWN_VISIBLE_ONLINE_POLLING_CHECK });
  }
}

// TM1POS-1548
export function* startPrinterSearch(action = {}) {
  const printerDropDownDetails = yield select(selectPrinterDropdownStatus(TmdsClient));
  if (action.type === PRINTER_DROPDOWN_VISIBLE_ONLINE_CHECK && !printerDropDownDetails.isOnScreen) {
    return;
  }
  try {
    const { selectedPrinter } = TmdsClient;

    // check if previously saved selected printer is still in printer list
    if (selectedPrinter?.id) {
      const isSelectedPrinterInList =
        TmdsClient.printerList.filter((printer) => printer.id === selectedPrinter.id).length > 0;
      if (!isSelectedPrinterInList) {
        TmdsClient.selectPrinter(null);
      }
    }
  } catch (error) {
    yield put({ type: FETCH_SEARCH_PRINTER.ERROR, error });
  }
}
export function* printCheck() {
  const chan = yield actionChannel([DO_DELIVERY_END], buffers.expanding(10));
  while (true) {
    try {
      const action = yield take(chan);

      const filesToPrint = action?.data?.filesToPrint || [];

      yield call(print, filesToPrint);
    } catch (e) {
      console.log('tmds printing status error ', e); // eslint-disable-line no-console
    }
  }
}

export function* clientCloudLog(action = {}) {
  const {
    log: { type, status, data },
  } = action;
  yield fork(logToCloud, {
    type,
    status,
    data,
  });
}

export function* watchLogout() {
  yield takeEvery(USER_TIMEOUT, oidcLogout);
  yield takeEvery(userSignout.type, oidcLogout);
  yield takeEvery(JWT_TOKEN_EXPIRED, oidcLogout);
}

export function* watchClearCart() {
  yield takeEvery(CLEAR_CART, clearCart);
}

export function* watchUnReserveSingleSeat() {
  yield takeEvery(PLACE_TO_RESERVE_DESELECT, unReserveSingleSeat);
}

export function* watchPromptOrderDelete() {
  yield takeEvery(PROMPT_ORDER_DELETE, handlePromptOrderDelete);
}

export function* watchGetFeedbackContacts() {
  yield takeEvery(GET_FEEDBACK_CONTACTS, fetchFeedbackContacts);
}

export function* watchSubmitFeedback() {
  yield takeEvery(SUBMIT_FEEDBACK, submitFeedback);
}

export function* watchInitializePrintingModule() {
  yield takeLatest([initializePrintingModule.type], initializePrintModule);
}

// TM1POS-1548
export function* watchGetTMDSDeviceDetails() {
  yield takeLatest([SEARCH_PRINTER], getTmdsDeviceDetails);
}

// TM1POS-1548
export function* getTmdsDeviceDetails() {
  const printingModuleEnabled = yield select(selectPrintingModuleEnabled);
  if (!printingModuleEnabled) {
    yield call(TmdsClient.getTmdsDeviceDetails);
  }
}

// TM1POS-1548
export function* watchPrinterSearch() {
  yield takeEvery([SEARCH_PRINTER, PRINTER_DROPDOWN_VISIBLE_ONLINE_CHECK], startPrinterSearch);
}

export function* tmdsStatusCheck(action) {
  const { message, token, requestid, timeouts } = action;
  yield call(TmdsClient.tmdsStatusCheck, { message, token, requestid, timeouts });
}

export function* watchForStatusCheck() {
  yield takeEvery([TMDS_STATUS_CHECK], tmdsStatusCheck);
}

export function* watchForClientCloudLog() {
  yield takeEvery([CLIENT_CLOUD_LOG], clientCloudLog);
}

export default [
  watchLogin,
  watchLogout,
  watchClearCart,
  watchFetchAdditionalUserData,
  watchCriticalError,
  watchGetFeedbackContacts,
  watchSubmitFeedback,
  watchPromptOrderDelete,
  watchFinishFetchUserData,
  watchInitializePrintingModule,
  watchForStatusCheck,
  checkPrinterDropdownStatus,
  watchUnReserveSingleSeat,
  printCheck,
  watchPrinterSearch,
  watchForClientCloudLog,
  watchSetupFeatureFlagsCompleted,
  watchStartFetchingUserInformation,
  watchLocaleRetrievalCompleted,
  watchStartFetchingEvents,
  watchGetTMDSDeviceDetails,
];
