import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { LogComponent } from '../../errorHandling/constants';
import { dispatchError } from '../../services/errors/error-dispatcher';
const isPromiseRejectionEvent = (event) => event && 'promise' in event;
const isICEPromiseRejectionEvent = (event) => event && 'detail' in event && isPromiseRejectionEvent(event.detail);
export const ErrorBoundary = ({ errorPage, children }) => {
    const dispatch = useDispatch();
    const errorHandler = (error) => dispatchError(error, LogComponent.UNHANDLED_COMPONENT_ERROR, { dispatch });
    const dispatchUnhandledError = (error) => {
        error.preventDefault();
        dispatchError(error.error, LogComponent.UNHANDLED_WINDOW_ERROR, { dispatch });
    };
    const dispatchUnhandledRejection = async (promiseRejectionEvent) => {
        promiseRejectionEvent.preventDefault();
        let promise = null;
        if (isPromiseRejectionEvent(promiseRejectionEvent)) {
            promise = promiseRejectionEvent.promise;
        }
        else if (isICEPromiseRejectionEvent(promiseRejectionEvent)) {
            promise = promiseRejectionEvent.detail.promise;
        }
        if (promise) {
            promise.catch((error) => {
                dispatchError(error, LogComponent.UNHANDLED_REJECTION_ERROR, { dispatch });
            });
        }
        else {
            console.error(promiseRejectionEvent);
        }
    };
    useEffect(() => {
        window.addEventListener('error', dispatchUnhandledError);
        window.addEventListener('unhandledrejection', dispatchUnhandledRejection);
        return () => {
            window.removeEventListener('error', dispatchUnhandledError);
            window.removeEventListener('unhandledrejection', dispatchUnhandledRejection);
        };
    }, []);
    return (_jsx(ReactErrorBoundary, { fallback: errorPage, onError: errorHandler, children: children }));
};
