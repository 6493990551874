import { SYSTEM_ID } from '@ticketmaster/tm1pos-web-shared/constants';
import { selectFeatureFlags } from '@ticketmaster/tm1pos-web-shared/feature-flags';
import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { getCheckoutTarget } from '@ticketmaster/tm1pos-web-shared/payment/payment-functions';
import {
  selectCurrentEventDetails,
  selectCurrentHostEventPermissions,
} from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectArchticsPermissions } from '@ticketmaster/tm1pos-web-shared/store/selectors/permissions-selector';
import { selectUserInformation } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { createSelector } from 'reselect';
import type { PaymentPermissions, Permissions } from '@ticketmaster/tm1pos-web-shared/model/permissions';

export const selectCurrentEventPermissions = createSelector(
  selectCurrentEventDetails,
  selectUserInformation,
  selectCurrentHostEventPermissions,
  selectArchticsPermissions,
  (currentEvent, userInformation, hostPermissions, archticsPermissions) => {
    let permissions: Permissions | undefined;
    const target = getCheckoutTarget(userInformation, currentEvent?.id);
    if (target === SYSTEM_ID.ARCHTICS) {
      permissions = archticsPermissions;
    }
    if (target === SYSTEM_ID.HOST) {
      permissions = hostPermissions;
    }
    return permissions;
  },
);

export const selectCurrentEventPaymentPermissions = createSelector(
  selectCurrentEventPermissions,
  (currentEventPermissions: PaymentPermissions | undefined) => currentEventPermissions,
);

export const selectLinkGlobalAccountEnabled = createSelector(
  selectCurrentEventPermissions,
  selectFeatureFlags,
  (currentEventPermissions: Permissions | undefined, flags) =>
    (currentEventPermissions?.canLinkGlobalAccount && flags.linkGlobalAccount) || false,
);

export const selectManualCardEntryAllowed = createSelector(
  selectEmvPaymentModuleState,
  selectCurrentEventPaymentPermissions,
  ({ isEmvSupported }, permissions) => isEmvSupported || permissions?.canUseCcManualEntryWithoutEmv || false,
);

export const selectManualCardEntryDisabled = createSelector(selectEmvPaymentModuleState, (state) => {
  if (state.rules.shouldSelectDeviceFromList) {
    return state.devices === 'loading' || !state.devices.length || !state.deviceId;
  }
  return false;
});

export const selectCardPaymentEnabled = createSelector(
  selectCurrentEventPaymentPermissions,
  selectEmvPaymentModuleState,
  selectManualCardEntryAllowed,
  (permissions, { isEmvSupported }, manualCardEntryAllowed) => {
    if (permissions?.mustAuthorizeCards) {
      return isEmvSupported || manualCardEntryAllowed;
    }
    return true;
  },
);
