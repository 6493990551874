import { DropDownGroup, DropDownLabel, DropDownOption } from '@ticketmaster/aurora';
import { IconRefresh } from '@ticketmaster/tm1pos-web-shared/components/icons';
import { Tm1ProgressRing, Tm1ThemeProvider } from '@ticketmaster/tm1pos-web-shared/components/v2';
import messages from '@ticketmaster/tm1pos-web-shared/payment/payment-messages';
import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import type { PaymentDevice, PaymentDevices } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import type { InjectedIntl } from 'react-intl';
import './EMVPaymentForm.scss';

type InjectedProps = {
  intl: InjectedIntl;
};
export type Props = {
  paymentDevices: PaymentDevices;
  selectedDeviceId?: string | null;
  onPaymentDeviceSelected: (deviceId: string) => void;
  onRefresh: () => void;
};
const PaymentDeviceList = ({
  paymentDevices,
  onPaymentDeviceSelected,
  selectedDeviceId,
  onRefresh,
  intl,
}: InjectedProps & Props) => {
  const [dropdownValue, setDropdownValue] = useState<string[]>([]);
  const [sortedDevices, setSortedDevices] = useState<PaymentDevice[]>([]);

  useEffect(() => {
    if (selectedDeviceId) {
      setDropdownValue([selectedDeviceId]);
    }
    if (paymentDevices === 'loading') {
      setSortedDevices([]);
    } else {
      setSortedDevices(
        [...paymentDevices].sort((device1, device2) => getDeviceName(device1).localeCompare(getDeviceName(device2))),
      );
    }
  }, [selectedDeviceId, setDropdownValue, paymentDevices]);

  const onDeviceChange = (value: string[] | null) => {
    if (value?.length) {
      onPaymentDeviceSelected(value[0]);
    }
  };

  const getDeviceName = (device: PaymentDevice): string => device.name || device.id;

  const refreshButton = () => {
    if (paymentDevices === 'loading') {
      return (
        <div className="progress-ring-container">
          <Tm1ThemeProvider>
            <Tm1ProgressRing size="xsmall" />
          </Tm1ThemeProvider>
        </div>
      );
    }
    return (
      <div>
        <IconRefresh onClick={onRefresh} />
      </div>
    );
  };

  return (
    <div className="dropdown-device-list" role="list">
      <DropDownLabel className="dropdown-device-list__label" size="small">
        <FormattedMessage {...messages.paymentDeviceListLabel} />
      </DropDownLabel>
      <div className="dropdown-device-list__options">
        <DropDownGroup
          data-testid="dropdown-device-list"
          size="small"
          aria-disabled={!sortedDevices.length}
          disabled={!sortedDevices.length}
          placeholder={intl.formatMessage(messages.paymentDeviceListPlaceholder)}
          value={dropdownValue}
          valueOverride={dropdownValue}
          onChange={onDeviceChange}
          fullWidth
        >
          {sortedDevices.map((device, index) => (
            <DropDownOption aria-label={getDeviceName(device)} value={device.id} key={device.id} index={index}>
              {getDeviceName(device)}
            </DropDownOption>
          ))}
        </DropDownGroup>
        {refreshButton()}
      </div>
    </div>
  );
};
export default injectIntl(PaymentDeviceList);
