import { LogComponent, LogStatus } from '@ticketmaster/tm1pos-web-shared/errorHandling/constants';
import * as ErrorHandler from '@ticketmaster/tm1pos-web-shared/services/errors/error-handler';
import { logClientToCloud } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { delay, eventChannel } from 'redux-saga';
import { put, select, take, takeEvery } from 'redux-saga/effects';
import { call } from 'redux-saga-test-plan/matchers';
import { ISM_WEBSOCKET_UPDATES_THROTTLE_RATE } from '../../constants';
import { IsmrtSubscriptionClient } from '../../services/ismrt-client/ismrt-subscription-client';
import { ISMRT_SNAPSHOT_RELOAD } from './constants';
import { mapIsmrtPlaceUpdatesToSeatStatus } from './containers/SearchMap/mapper';
import {
  reloadIsmrtSnapshot,
  subscribeToIsmrtPlaceUpdates,
  unsubscribeFromIsmrtPlaceUpdates,
  updateSeatStatuses,
} from './event-actions';
import { selectCurrentEvent } from './selectors/main';
import type { IsmrtPlaceUpdate, IsmrtReconnect } from './model/IsmrtPlaceData';
import type { SeatStatusPlace } from './model/SeatStatusPlace';
import type { Channel } from 'redux-saga';
import type { SelectEffect } from 'redux-saga/effects';

// Avoids websocket connection errors if browser is too busy
const EVENT_INITIALIZATION_GRACE_DELAY = 5000;

export const state: {
  currentReadChannel: Channel<IsmrtPlaceUpdate | Error | IsmrtReconnect> | null;
  currentExecuteChannel: Channel<IsmrtPlaceUpdate[]> | null;
  updatesPendingExecution: IsmrtPlaceUpdate[];
} = {
  currentReadChannel: null,
  currentExecuteChannel: null,
  updatesPendingExecution: [],
};

export function* watchReadPlaceUpdates() {
  yield takeEvery(subscribeToIsmrtPlaceUpdates.type, readPlaceUpdates);
}

export function* watchExecutePlaceUpdates() {
  yield takeEvery(subscribeToIsmrtPlaceUpdates.type, executePlaceUpdates);
}

export function* watchStopReadingPlaceUpdates() {
  yield takeEvery(unsubscribeFromIsmrtPlaceUpdates.type, stopReadingPlaceUpdates);
}

export function* watchStopExecutingPlaceUpdates() {
  yield takeEvery(unsubscribeFromIsmrtPlaceUpdates.type, stopExecutingPlaceUpdates);
}

export function* readPlaceUpdates() {
  stopReadingPlaceUpdates();

  const channel: Channel<IsmrtPlaceUpdate | Error | IsmrtReconnect> = yield call(createReadPlaceUpdatesChannel);
  state.currentReadChannel = channel;

  yield call(delay, EVENT_INITIALIZATION_GRACE_DELAY);

  while (true) {
    try {
      const placeUpdate: IsmrtPlaceUpdate | IsmrtReconnect = yield take(channel);
      if (placeUpdate === ISMRT_SNAPSHOT_RELOAD) {
        yield put(reloadIsmrtSnapshot());
      } else {
        state.updatesPendingExecution.push(placeUpdate);
      }
    } catch (error) {
      yield put(
        logClientToCloud({
          data: { error: ErrorHandler.toLogError(error) },
          status: LogStatus.ERROR,
          type: LogComponent.ISMRT_SUBSCRIPTION,
        }),
      );
    }
  }
}

export function* executePlaceUpdates() {
  stopExecutingPlaceUpdates();

  const channel: Channel<IsmrtPlaceUpdate[]> = yield call(createExecutePlaceUpdatesChannel);
  state.currentExecuteChannel = channel;

  while (true) {
    const placeUpdates: IsmrtPlaceUpdate[] = yield take(channel);
    const seatStatusPlaces: SeatStatusPlace[] = yield call(mapIsmrtPlaceUpdatesToSeatStatus, placeUpdates);

    const updateSeatStatusesAction = updateSeatStatuses({
      placeUpdates: seatStatusPlaces,
    });

    yield put(updateSeatStatusesAction);
  }
}

export function* createReadPlaceUpdatesChannel(): Generator<
  SelectEffect,
  Channel<IsmrtPlaceUpdate | IsmrtReconnect | Error>,
  ReturnType<typeof selectCurrentEvent>
> {
  const currentEvent: ReturnType<typeof selectCurrentEvent> = yield select(selectCurrentEvent);

  return eventChannel((emit) => {
    const ismrtSubscriptionClient = new IsmrtSubscriptionClient(
      currentEvent.seatAttributeToken,
      currentEvent.id,
      (error) => emit(error),
      () => emit(ISMRT_SNAPSHOT_RELOAD),
    );

    ismrtSubscriptionClient.subscribeToPlaceUpdates((placeUpdate) => emit(placeUpdate));

    return () => ismrtSubscriptionClient.unsubscribeFromPlaceUpdates();
  });
}

export function createExecutePlaceUpdatesChannel(): Channel<IsmrtPlaceUpdate[]> {
  return eventChannel((emit) => {
    const executePlaceUpdatesInterval = setInterval(() => {
      if (state.updatesPendingExecution.length) {
        emit([...state.updatesPendingExecution]);
        state.updatesPendingExecution = [];
      }
    }, ISM_WEBSOCKET_UPDATES_THROTTLE_RATE);

    return () => clearInterval(executePlaceUpdatesInterval);
  });
}

export function stopReadingPlaceUpdates() {
  if (state.currentReadChannel) {
    state.currentReadChannel.close();
    state.currentReadChannel = null;
  }

  state.updatesPendingExecution = [];
}

export function stopExecutingPlaceUpdates() {
  if (state.currentExecuteChannel) {
    state.currentExecuteChannel.close();
    state.currentExecuteChannel = null;
  }
}

export const eventSubscriptionSagas = [
  watchReadPlaceUpdates,
  watchExecutePlaceUpdates,
  watchStopReadingPlaceUpdates,
  watchStopExecutingPlaceUpdates,
];
