import Stepper from '@ticketmaster/tm1pos-web-shared/components/Stepper/Stepper';
import { currencyFormatOptions } from '@ticketmaster/tm1pos-web-shared/constants';
import { Tm1Checkbox, Tm1DataGridCell, Tm1DataGridRow } from '@tm1/design-system-react';
import Big from 'big.js';
import { FormattedNumber, injectIntl } from 'react-intl';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectIsLoadingEventFees } from '../../../app-selectors';
import type { OtherCharge } from './model/other-charge';

type Props = {
  item: OtherCharge;
  onOtherChargeChange: (otherCharge: OtherCharge) => void;
};

const EditOtherChargesGridItem = ({ item, onOtherChargeChange }: Props) => {
  const isLoadingOtherCharges = useSelector(selectIsLoadingEventFees);

  const isQuantityGreaterThanZero = () => (item.quantity || 0) > 0;

  const toggleItemCheckbox = () => {
    onOtherChargeChange({
      ...item,
      quantity: isQuantityGreaterThanZero() ? undefined : 1,
    });
  };

  const applyStepperValueChange = (newValue?: number) => {
    onOtherChargeChange({
      ...item,
      quantity: newValue,
    });
  };

  const calculateTotal = () => ({
    amount: new Big(item.price.amount).mul(item.quantity || 0).toNumber(),
    currency: item.price.currency,
  });

  const total = calculateTotal();

  return (
    <Tm1DataGridRow className="edit-other-charges-grid__item" data-testid={`other-charge-item-${item.id}`}>
      <Tm1DataGridCell gridColumn="1" className="edit-other-charges-grid__checkbox-cell">
        <Tm1Checkbox
          size="small"
          className="edit-other-charges-grid__checkbox"
          onClick={() => toggleItemCheckbox()}
          checked={isQuantityGreaterThanZero()}
          disabled={isLoadingOtherCharges}
          data-testid={`other-charge-item-checkbox-${item.id}`}
        />
      </Tm1DataGridCell>
      <Tm1DataGridCell gridColumn="2">{item.description}</Tm1DataGridCell>
      <Tm1DataGridCell gridColumn="3">
        <FormattedNumber value={item.price.amount} currency={item.price.currency} {...currencyFormatOptions} />
      </Tm1DataGridCell>
      <Tm1DataGridCell gridColumn="4" className="edit-other-charges-grid__stepper-cell">
        <Stepper
          value={item.quantity}
          placeholder="0"
          onValueChange={(newValue) => applyStepperValueChange(newValue)}
          disabled={isLoadingOtherCharges}
          maxQuantity={item.maxQuantity}
        />
      </Tm1DataGridCell>
      <Tm1DataGridCell gridColumn="5">
        {isQuantityGreaterThanZero() ? (
          <FormattedNumber value={total.amount} currency={total.currency} {...currencyFormatOptions} />
        ) : (
          '-'
        )}
      </Tm1DataGridCell>
    </Tm1DataGridRow>
  );
};

export default injectIntl(EditOtherChargesGridItem);
