import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../messages';
import DeliveryResult from './DeliveryResult';
import type { InjectedIntlProps } from 'react-intl';

export type DeliveryRowProps = {
  delivery?: string;
  deliveryMethod?: string;
  deliverySuccess?: boolean;
} & InjectedIntlProps;
export const DeliveryRow = (props: DeliveryRowProps) => {
  const { delivery, deliveryMethod, deliverySuccess } = props;

  return (
    <div className="order-detail__row">
      <div className="order-detail__col order-detail__col--left">
        <FormattedMessage {...messages.delivery} />
      </div>
      <div className="order-detail__col order-detail__col--right">
        <DeliveryResult {...{ delivery, deliveryMethod, deliverySuccess }} />
      </div>
    </div>
  );
};

export default injectIntl(DeliveryRow);
