import SmallSpinnerComponent from '@ticketmaster/tm1pos-web-shared/components/SmallSpinnerComponent';
import { EMAIL, PRINT, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../../messages';
import type * as React from 'react';
import type { InjectedIntl } from 'react-intl';

type DeliveryResultProps = {
  children: React.ReactNode;
};
export const DeliveryResultWrapper = ({ children }: DeliveryResultProps) => (
  <div className="sdr-order-conf__delivery">{children}</div>
);

export type ExposedProps = {
  delivery?: string;
  deliveryMethod?: string;
  deliverySuccess?: boolean;
};
export type InjectedProps = {
  intl: InjectedIntl;
};
export type Props = ExposedProps & InjectedProps;
export const DeliveryResult = ({ delivery, deliveryMethod, deliverySuccess }: Props) => {
  if (!deliveryMethod) {
    return <SmallSpinnerComponent />;
  }

  if (!deliverySuccess) {
    return <FormattedMessage {...messages.failStatusMessage} />;
  }

  switch (deliveryMethod) {
    case EMAIL:
      return (
        <DeliveryResultWrapper>
          <span>{delivery}</span>
        </DeliveryResultWrapper>
      );
    case SMS:
      return (
        <DeliveryResultWrapper>
          <FormattedMessage {...messages.smsDelivery} values={{ number: delivery }} />
        </DeliveryResultWrapper>
      );
    case PRINT:
      return (
        <DeliveryResultWrapper>
          <FormattedMessage {...messages.printDelivery} />
        </DeliveryResultWrapper>
      );
    default:
      return null;
  }
};

export default injectIntl(DeliveryResult);
