import { Tm1DataGridCell, Tm1DataGridRow, Tm1Icon, Tm1Tooltip } from '@tm1/design-system-react';
import { type InjectedIntl, injectIntl } from 'react-intl';
import messages from '../messages';

import './styles.scss';

type InjectedProps = {
  intl: InjectedIntl;
};

export type Props = {
  maxValue: number;
  hideTooltip: boolean;
};

const EditOtherChargesGridHeader = ({ maxValue, hideTooltip, intl }: Props & InjectedProps) => (
  <Tm1DataGridRow
    rowType="sticky-header"
    className="edit-other-charges-grid__header"
    data-testId="edit-other-charges-header"
  >
    <Tm1DataGridCell cellType="columnheader" gridColumn="1"></Tm1DataGridCell>
    <Tm1DataGridCell cellType="columnheader" gridColumn="2">
      {intl.formatMessage(messages.chargeDescription)}
    </Tm1DataGridCell>
    <Tm1DataGridCell cellType="columnheader" gridColumn="3">
      {intl.formatMessage(messages.chargePrice)}
    </Tm1DataGridCell>
    <Tm1DataGridCell cellType="columnheader" gridColumn="4">
      {intl.formatMessage(messages.chargeQuantity)}
      <Tm1Icon id="quantityInfoIcon" size="small" icon="info" className="edit-other-charges-grid__quantity-icon" />
      {hideTooltip ? null : (
        <Tm1Tooltip anchor="quantityInfoIcon" position="top" data-testid="quantity-info-icon">
          {intl.formatMessage(messages.chargeMaxQuantity, { maxValue })}
        </Tm1Tooltip>
      )}
    </Tm1DataGridCell>
    <Tm1DataGridCell cellType="columnheader" gridColumn="5">
      {intl.formatMessage(messages.chargeTotal)}
    </Tm1DataGridCell>
  </Tm1DataGridRow>
);

export default injectIntl(EditOtherChargesGridHeader);
