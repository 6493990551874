import LoadingComponent from '@ticketmaster/tm1pos-web-shared/components/LoadingComponent';
import { START_USER_DATA_RETRIEVAL } from '@ticketmaster/tm1pos-web-shared/store/actions-constants';
import { LanguageProvider, selectTranslationsLoaded } from '@ticketmaster/tm1pos-web-shared/translations';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tm1Bootstrap } from '../../../../components/tm1/tm1-bootstrap';
import { useAppSelector } from '../../../../store/hooks';
import { selectAdditionalUserDataLoaded } from '../../selectors';
import type { PropsWithChildren } from 'react';

export type ComponentsDependenciesLoaderProps = PropsWithChildren<unknown>;

export const ComponentsDependenciesLoader = ({ children }: ComponentsDependenciesLoaderProps) => {
  const dispatch = useDispatch();

  const additionalUserDataLoaded = useAppSelector(selectAdditionalUserDataLoaded);
  const isTranslationsLoaded = useAppSelector(selectTranslationsLoaded);

  const isReady = additionalUserDataLoaded && isTranslationsLoaded;

  useEffect(() => {
    dispatch({ type: START_USER_DATA_RETRIEVAL });
  }, [dispatch]);

  if (!isReady) {
    return <LoadingComponent largeGear />;
  }

  return (
    <Tm1Bootstrap>
      <LanguageProvider>{children}</LanguageProvider>
    </Tm1Bootstrap>
  );
};
