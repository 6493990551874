import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { fetchPaymentDevices, setDeviceId } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectEMVSetupError } from 'containers/Checkout/selectors';
import { useAppDispatch } from '../../../../../../store/hooks';
import EMVPaymentForm from './EMVPaymentForm';
import type { Props as ManualCardEntryProps } from '../RenderPaymentForm/ManualCardEntry';

const EMVPaymentFormConnected = ({
  manualCardEntry,
  showManualCardEntry,
}: {
  manualCardEntry: ManualCardEntryProps;
  showManualCardEntry: boolean;
}) => {
  const dispatch = useAppDispatch();
  const {
    deviceId,
    devices,
    rules: { shouldSelectDeviceFromList },
  } = useSelector(selectEmvPaymentModuleState);
  const emvSetupError = useSelector(selectEMVSetupError);
  const onPaymentDeviceSelected = (id: string) => {
    dispatch(setDeviceId(id));
  };
  const onRefreshPaymentDevices = () => {
    dispatch(fetchPaymentDevices());
  };

  return (
    <EMVPaymentForm
      paymentDevices={devices}
      selectedDeviceId={isEmpty(deviceId) || !deviceId ? undefined : deviceId}
      setupError={emvSetupError}
      showDeviceList={shouldSelectDeviceFromList}
      onPaymentDeviceSelected={onPaymentDeviceSelected}
      onRefresh={onRefreshPaymentDevices}
      manualCardEntry={manualCardEntry}
      showManualCardEntry={showManualCardEntry}
    />
  );
};
export default EMVPaymentFormConnected;
