/* eslint-disable sonarjs/cognitive-complexity */
import { selectEventItems } from '@ticketmaster/tm1pos-web-shared/store/events/events-selector';
import {
  selectCurrentEventCountryCode,
  selectCurrentEventDetails,
  selectSelectedInventoryType,
  selectSortedHostInventoryTypes,
} from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectHostPermissions } from '@ticketmaster/tm1pos-web-shared/store/selectors/permissions-selector';
import { selectUserRoles } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import storage from '@ticketmaster/tm1pos-web-shared/utils/storage/storageService';
import { groupBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { STORAGE_UNPAID_ORDER_ID } from '../../App/constants';
import { selectBestAvailableCartSeats, selectBestAvailableCartSeatsIds, selectCartItems } from '../../App/selectors';
import { HOST_EVENT_TYPE } from '../constants';
import { selectAdaTypes, selectSortedArchticsInventoryTypes } from './adaTypes';
import { getTicketTypeName, selectLoadingAvailabilityData, selectSeatStatus } from './seatStatus';
import { selectCompoundEventId, selectEventIds, selectEventType } from './selectEventIds';
import { selectIsLoadingTicketTypes, selectTicketTypes } from './ticketType';
import type { FormattedReservedItems } from './FormattedReservedItems';
import type { EventFee } from '../../../services/sales-api-client/model/order-fee';
import type { PriceLevelGroup } from '../../../services/sales-api-client/model/price-level-groups';
import type { NullableOptional } from '@ticketmaster/tm1pos-web-shared/typings/common-types';

const selectSeatStatusSummary = (state: any) => state.event.get('seatStatusSummary');
const selectLoadingSummaryState = (state: any) => state.event.get('isLoading');
const selectSeatAttributeToken = (state: any) => state.event.get('seatAttributeToken');
const selectIsLoadingBestAvailable = (state: any) => state.event.get('isLoadingBestAvailable');
const selectBestAvailableError = (state: any) => state.event.get('bestAvailableDisplayError');
const selectIsLoadingPlaceReservation = (state: any) => state.event.get('isLoadingPlaceReservation');
const selectIsmLoadFailed = (state: any) => state.event.get('ismLoadFailed');
const selectUserDataFromState = (state: any) => state.oidc;
const selectShowBestAvailablePriceModal = (state: any) => state.event.get('showBestAvailPriceModal');
const selectSeatsSelected = (state: any) => state.event.get('selectedSeats');
const selectEventLoadedHasDispatched = (state: any) => state.event.get('eventLoadedHasDispatched');
const selectArchticsFailedAvailabilityCalls = (state: any) => state.event.get('archticsFailedAvailabilityCalls');

const selectIsOrderDeleteInProgress = (state: any) => state.event?.get('isOrderDeleteInProgress');

const selectOrderIdToRetry = (state: any): string => {
  if (storage.getItem(STORAGE_UNPAID_ORDER_ID)) {
    return storage.getItem(STORAGE_UNPAID_ORDER_ID);
  }

  if (state.event) {
    return state.event.get('orderIdToRetry');
  }

  return '';
};

export const selectIsLoadingCurrentEventPage = createSelector(
  (state: any) => state.event.get('isLoadingCurrentEventPage'),
  (isLoadingCurrentEventPage) => isLoadingCurrentEventPage,
);

export const selectIsLoadingIsmrtSnapshot = createSelector(
  (state: any) => state.event.get('isLoadingIsmrtSnapshot'),
  (isLoadingIsmrtSnapshot) => isLoadingIsmrtSnapshot,
);

export const selectIsGeometryLoaded = createSelector(
  (state: any) => state.event.get('isGeometryLoaded'),
  (isGeometryLoaded) => isGeometryLoaded,
);

export const selectIsLoadingInventoryTypes = createSelector(
  (state: any) => state.event.get('isLoadingInventoryTypes'),
  (isLoadingInventoryTypes) => isLoadingInventoryTypes,
);

export const selectPriceLevelGroupIdMap = createSelector(
  (state: any) => state.event.get('priceLevelGroups'),
  (priceLevelGroups: PriceLevelGroup[]) => {
    const priceLevelIdMap = new Map<string, string>();
    for (const group of priceLevelGroups) {
      for (const priceLevelId of group.priceLevelIds) {
        priceLevelIdMap.set(priceLevelId, group.id);
      }
    }
    return priceLevelIdMap;
  },
);

export const selectCurrentEventFees = createSelector(
  (state: any) => state.event?.get('eventFees') || [],
  (eventFees: EventFee[]) => eventFees,
);

export const selectIsEventDataLoaded = createSelector(
  selectIsLoadingTicketTypes,
  selectIsLoadingInventoryTypes,
  selectLoadingAvailabilityData,
  selectIsLoadingIsmrtSnapshot,
  selectIsGeometryLoaded,
  (
    isLoadingTicketTypes,
    isLoadingInventoryTypes,
    isLoadingAvailabilityData,
    isLoadingIsmrtSnapshot,
    isGeometryLoaded,
  ) =>
    !isLoadingTicketTypes &&
    !isLoadingInventoryTypes &&
    !isLoadingAvailabilityData &&
    !isLoadingIsmrtSnapshot &&
    isGeometryLoaded,
);

export const selectQualifiedHoldsToggle = (state: any) => !!state.app.get('qualifiedHoldsToggle');

export const selectQualifiedHolds = createSelector(selectSeatStatus, (places: NullableOptional<PlaceISM[]>) => {
  if (!places) {
    return null;
  }

  const qualifiedHoldsWithPlace = places
    .map((place) => ({
      id: place.id,
      ticketType: place.ticketType,
      accessibility: place.accessibility,
    }))
    .filter((place) => !!place.ticketType)
    .filter(({ ticketType }) => ticketType.status === 'HOLD')
    .filter(({ ticketType }) => ticketType.offerSets != null)
    .flatMap(({ id, ticketType, accessibility }) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ticketType.offerSets!.map((offer) => {
        const parentName = getTicketTypeName(ticketType.encoded);
        return {
          id: ticketType.encoded,
          parent: parentName,
          name: offer.id,
          place: id,
          accessibility,
        };
      }),
    );

  const uniqQualifierHoldsByIdNameAndParent = uniqBy(
    qualifiedHoldsWithPlace,
    (hold) => hold.id + hold.name + hold.parent,
  ).map((qualifiedHoldWithPlace) => {
    const { place, ...qualifiedHold } = qualifiedHoldWithPlace;
    const isAccessible = (qualifiedHoldWithPlace.accessibility?.length || 0) > 0;

    return {
      id: qualifiedHold.id,
      parent: qualifiedHold.parent,
      name: qualifiedHold.name,
      isAccessible,
    };
  });

  const uniqQualifierHoldsByNameParentAndAccessibility = uniqBy(
    uniqQualifierHoldsByIdNameAndParent,
    (hold) => hold.name + hold.parent + hold.isAccessible,
  );

  // It is possible to have multiple ETTs on the same qualified hold; the ETT will be different when
  // the seat is WheelChair vs Companion vs a standard seat.
  //
  // We choose the ETT for the standard seats by default, but fallback to the first accessible ETT if
  // all the seats of the qualified hold are accessible.
  const qualifiedHolds: QualifiedHold[] = [];
  Object.values(groupBy(uniqQualifierHoldsByNameParentAndAccessibility, (hold) => hold.name + hold.parent)).forEach(
    (it) => {
      if (it.length === 1) {
        qualifiedHolds.push(...it);
      } else {
        qualifiedHolds.push(...it.filter((x) => !x.isAccessible));
      }
    },
  );
  return qualifiedHolds;
});

export const selectInventoryTypesWithQualifiedHolds = createSelector(
  selectSortedHostInventoryTypes,
  selectQualifiedHolds,
  (inventoryTypes: InventoryType[] | null, qualifiedHolds: QualifiedHold[] | null) => {
    if (!inventoryTypes) {
      return [];
    }
    if (!qualifiedHolds) {
      return inventoryTypes;
    }

    return inventoryTypes.flatMap((type) =>
      [type].concat(
        qualifiedHolds
          .filter((hold) => hold.parent === type.name)
          .map((hold) => ({
            id: hold.id,
            name: hold.name,
            isAccessible: hold.isAccessible,
            parent: hold.parent,
          })),
      ),
    );
  },
);

export const selectInventoryTypes = createSelector(
  selectEventIds,
  selectSortedHostInventoryTypes,
  selectSortedArchticsInventoryTypes,
  selectQualifiedHoldsToggle,
  selectInventoryTypesWithQualifiedHolds,
  (eventIds, hostList, arxList, enableQualifiedHolds, hostListWithQualifiedHolds) => {
    if (eventIds.archtics) {
      return arxList;
    }

    if (eventIds.host) {
      return enableQualifiedHolds ? hostListWithQualifiedHolds : hostList;
    }

    return arxList;
  },
);

export const selectInventoryTypesWithoutQualifiedHolds = createSelector(
  selectEventIds,
  selectSortedHostInventoryTypes,
  selectSortedArchticsInventoryTypes,
  (eventIds, hostList, arxList) => {
    if (eventIds.archtics) {
      return arxList;
    }

    if (eventIds.host) {
      return hostList;
    }

    return arxList;
  },
);

export const selectHostDefaultInventoryType = createSelector(
  selectInventoryTypes,
  selectHostPermissions,
  (sellTypesList, hostPermissions?) => {
    try {
      if (hostPermissions) {
        const firstSelectedHost = Object.keys(hostPermissions)[0];
        const defaultSellClass = hostPermissions[firstSelectedHost].defaultSellClass;
        if (sellTypesList.some((item: any) => item.id === defaultSellClass.id)) {
          return defaultSellClass;
        }
      }
    } catch (e) {
      console.error('No default host sell class available');
    }

    return null;
  },
);

export const selectDefaultInventoryType = (state: any) => state.app.get('defaultInventoryType');

const selectSeatsSelectedId = createSelector(
  selectSeatsSelected,
  selectBestAvailableCartSeatsIds,
  (seatsSelected, seatsSelectedBA) => Object.keys(seatsSelected).concat(seatsSelectedBA),
);

const selectCurrentEventFromItems = createSelector(selectEventItems, selectCompoundEventId, (events, eventId) => {
  if (events && events.find && eventId) {
    return events.find(({ id }: any) => id === eventId);
  }
  return undefined;
});

const selectCurrentEvent = createSelector(
  [selectEventIds, selectEventType, selectSeatAttributeToken, selectCurrentEventDetails, selectCurrentEventCountryCode],
  (ids, type, tokenRes, currentEvent, countryCode) => {
    const isHost = type === HOST_EVENT_TYPE;
    const id = isHost ? ids.host : ids.archtics;
    const currentMapId = currentEvent?.mapId;
    const rotatingEntryTokenEnabled = currentEvent?.rotatingEntryTokenEnabled;

    let mapId = null;
    if (currentMapId) {
      mapId = isHost ? ids.host : currentMapId;
    }
    const seatAttributeToken = tokenRes || '';
    return { id, type, mapId, countryCode, seatAttributeToken, rotatingEntryTokenEnabled, isHost };
  },
);

// select items from shopping cart for current event only
const selectItemsForShoppingBag = createSelector(
  [selectCompoundEventId, selectCartItems],
  (currentEventId, cartItems) => cartItems.filter((item) => item.eventId === currentEventId),
);

const selectViaISM = createSelector([selectSeatsSelected], (selectedViaIsm) => {
  if (selectedViaIsm.size === 0) return 0;
  const gaCount = selectedViaIsm.GA ? selectedViaIsm.GA.count - 1 : 0;
  return Object.keys(selectedViaIsm).length + gaCount;
});

const selectLoadingQtyInShoppingBag = createSelector(
  [selectViaISM, selectBestAvailableCartSeats, selectItemsForShoppingBag],
  (selectedViaIsm, selectedViaBA, shoppingBag) => selectedViaIsm + selectedViaBA.length - shoppingBag.length,
);
const selectTerminalId = createSelector([selectCurrentEventDetails], (eventDetails) =>
  checkDefined(eventDetails, ['terminalId']) ? eventDetails?.terminalId : null,
);

const findAdaType = (place: any, adaTypes: any, selectedSeats: any) =>
  adaTypes.find((ada: { id: string[] }) => ada.id.includes(place.adaType)) || selectedSeats[place.placeId]?.placeType;

const selectFormattedReservedItems = (items: any[], isBestAvailableFlag: boolean) =>
  createSelector(
    [
      selectCurrentEventDetails,
      selectTicketTypes,
      selectSelectedInventoryType,
      selectCartItems,
      selectSeatsSelected,
      selectAdaTypes,
    ],

    (currentEvent, ticketTypes, sellClass, cartItems, selectedSeats, adaTypes) => {
      const reservedItems: FormattedReservedItems = {};
      items.forEach((cartItem: any) => {
        let { eventId } = cartItem;
        if (!cartItem.eventId && cartItem.eventCode === currentEvent?.eventCode) {
          eventId = currentEvent?.id;
        }
        reservedItems[eventId] = reservedItems[eventId] || {
          sections: {},
          details: currentEvent,
          id: eventId,
          seatsCount: 0,
          orderId: {},
        };
        cartItem.places.forEach((place: any, index: number) => {
          reservedItems[eventId].sections[place.section] = reservedItems[eventId].sections[place.section] || {
            rows: {},
          };
          let gaID = '';
          let eventItemID = '';
          const isGA = place.generalAdmission;
          if (isGA) {
            gaID = place.placeId + cartItem.id + index;
            eventItemID = cartItem.id;
            reservedItems[eventId].orderId[gaID] = {
              id: cartItem.id,
              generalAdmission: place.generalAdmission,
              seatName: place.name,
            };
          } else {
            reservedItems[eventId].orderId[place.placeId] = {
              id: cartItem.id,
              generalAdmission: place.generalAdmission,
              seatName: place.name,
            };
          }
          const section = reservedItems[eventId].sections[place.section];
          section.rows[place.row] = section.rows[place.row] || { seats: [], name: place.row };
          const row = section.rows[place.row];

          let ticketTypeName = '';
          let ticketTypePromotion = '';

          if (ticketTypes && ticketTypes.length) {
            let ticketTypeForPlace = ticketTypes.find((type: any) => type.id === place.ticketType);
            ticketTypeForPlace =
              ticketTypeForPlace || ticketTypes.find((type: any) => type.id.slice(-2) === place.ticketType.slice(-2));
            ticketTypeName = ticketTypeForPlace.name;
            ticketTypePromotion = ticketTypeForPlace.promoCodes;
          }

          const placeInCart = isGA
            ? cartItems.filter((item) => item.gaID === gaID)
            : cartItems.filter((item) => item.placeId === place.placeId);
          const alreadyInCart = placeInCart.length === 1;
          const placeDataFromCart = alreadyInCart ? placeInCart[0] : null;
          const selectedPlaceGA = selectedSeats[place.placeId] ? selectedSeats[place.placeId].GA : false;

          const seatWasRemoved = items < cartItems;
          const filteredItems = cartItems.filter((c) => {
            const id = c.eventItemID;
            return cartItem.id !== id;
          });
          let isBestAvailable = isBestAvailableFlag;
          if (seatWasRemoved && filteredItems.length === cartItems.length) {
            isBestAvailable = true;
          }
          const placeWithTicketTypeName = {
            ...place,
            gaID,
            eventItemID,
            adaType: findAdaType(place, adaTypes, selectedSeats),
            placeId: gaID || place.placeId,
            bagWeight: reservedItems[eventId].seatsCount,
            price: place.pricing.total,
            isBestAvailable: alreadyInCart ? placeDataFromCart.isBestAvailable : isBestAvailable,
            GA: selectedPlaceGA || place.generalAdmission,
            eventId,
            sellClass: placeInCart.length ? placeInCart[0].sellClass : sellClass,
            type: {
              current: {
                id: place.ticketType,
                name: ticketTypeName,
                promotion: ticketTypePromotion ? ticketTypePromotion[0] : null,
              },
              all: {},
            },
          };
          reservedItems[eventId].seatsCount += 1;
          row.seats.push(placeWithTicketTypeName);
        });
      });
      return reservedItems;
    },
  );

export const selectDisplayQualifiedHoldsToggle = createSelector(
  selectUserRoles,
  selectQualifiedHolds,
  (userRoles, qualifiedHolds) => userRoles.includes('canSellLabeledHolds') && !!qualifiedHolds?.length,
);

export {
  selectTerminalId,
  selectCurrentEvent,
  selectCurrentEventDetails,
  selectCurrentEventFromItems,
  selectIsLoadingBestAvailable,
  selectBestAvailableError,
  selectIsLoadingPlaceReservation,
  selectSeatStatusSummary,
  selectLoadingSummaryState,
  selectSeatAttributeToken,
  selectUserDataFromState,
  selectShowBestAvailablePriceModal,
  selectItemsForShoppingBag,
  selectSeatsSelected,
  selectIsmLoadFailed,
  selectLoadingQtyInShoppingBag,
  selectFormattedReservedItems,
  selectEventLoadedHasDispatched,
  selectOrderIdToRetry,
  selectSeatsSelectedId,
  selectViaISM,
  selectSelectedInventoryType,
  selectArchticsFailedAvailabilityCalls,
  selectIsOrderDeleteInProgress,
};
export type PlaceISM = {
  id: string;
  ticketType: TicketTypeISM;
  status: string;
  sectionId: string;
  rowId: string;
  rating: string;
  priceZone: string;
  ticketTypeEncoded: string | null;
  placeInventoryType: string;
  placeIdNoInventoryType: string;
  restrictedOfferSets: boolean;
  accessibility: string[];
};
export type TicketTypeISM = {
  status: string;
  statusName: string;
  encoded: string;
  offerSets: OfferSetISM[] | null;
};
export type OfferSetISM = {
  id: string;
  restricted: boolean;
};
export type QualifiedHold = {
  id: string;
  parent: string;
  name: string;
  isAccessible: boolean;
};

export type InventoryType = {
  id: string;
  name: string;
  isAccessible?: boolean;
  parent?: string;
};
