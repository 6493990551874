import messages from '../../../../messages';
import type { InjectedIntl } from 'react-intl';

export class AuthorizationOffValidator {
  constructor(private intl: InjectedIntl) {}

  validateCode = (value: string | null) => {
    if (value && !/^\d+$/.test(value)) {
      return this.intl.formatMessage(messages.authorizationCodeDigitsOnly);
    }
    if (value && value.length > 9) {
      return [
        this.intl.formatMessage(messages.authorizationCodeMaximumLength),
        this.intl.formatMessage(messages.authorizationCodeMaximumNine),
      ];
    }
    return null;
  };
}

export const authorizationOffValidator = (intl: InjectedIntl) => new AuthorizationOffValidator(intl);
