/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */
import { combineReducers } from '@reduxjs/toolkit';
import { reducers as sharedReducers } from '@ticketmaster/tm1pos-web-shared/store/store';
import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS } from 'immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { reducer as oidcReducer } from 'redux-oidc';
import appReducer from './containers/App/reducer';
import checkoutFormReducer from './containers/Checkout/components/Form/reducer';
import { emailFormReducer } from './containers/ReportPage/reducer';
import type { AnyAction, Reducer } from '@reduxjs/toolkit';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
});

/**
 * Merge route into the global application state
 */
function routeReducer(state: any = routeInitialState, action: AnyAction) {
  // ? Disabling the `sonarjs/no-small-switch` rule to comply with the Reducer pattern
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      if (
        state.get('locationBeforeTransitions') &&
        action.payload.pathname === state.getIn(['locationBeforeTransitions', 'pathname'])
      ) {
        return state;
      }
      return state.merge({
        locationBeforeTransitions: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(lazyLoadedReducers: Record<string, Reducer> = {}) {
  return combineReducers({
    ...sharedReducers,
    app: appReducer,
    form: formReducer.plugin({
      checkout: checkoutFormReducer,
      reportForm: emailFormReducer,
    }),
    oidc: oidcReducer,
    route: routeReducer,
    ...lazyLoadedReducers,
  });
}
