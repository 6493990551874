import { FSADispatcher } from '@ticketmaster/tm1-core';
import { DEFAULT_LANGUAGE } from '@ticketmaster/tm1pos-web-shared/translations';
import { FeatureFlagsService } from '@tm1/core/lib/dependencies/feature-flags/v1';
import { DependenciesProvider } from '@tm1/core/lib/dependencies/v2/provider';
import { RegisterEventHandler } from '@tm1/core/lib/events/register-event/handler';
import _ from 'lodash';
import { AppTranslator } from './app-translator';
import { tm1ApiClient } from './tm1-api-client';
import type { ActivatedRoute, Logger, Translator } from '@ticketmaster/tm1-core';
import type { Tm1UserInfo } from '@ticketmaster/tm1pos-web-shared/model/tm1-user-info';
import type { Maybe } from 'graphql/jsutils/Maybe';

/**
 * @example
 * (arg1?: any, ...params: any[]): boolean => {
 *   if (typeof arg1 === 'undefined') {
 *     // Check if the current log level is enabled (noop operation)
 *   } else if (arg1 instanceof Error) {
 *     // Log an Error instance
 *     console.warn({ err: arg1, msg: arg1.message, ...params });
 *   } else if (typeof arg1 === 'object' && !Array.isArray(arg1)) {
 *     // Add some logging fields to the payload
 *     console.log({ ...arg1, ...params });
 *   } else if (typeof arg1 === 'string') {
 *     // Log any payload
 *     console.log(arg1, ...params);
 *   }
 *   return true;
 * },
 */
const defaultLogFunction = (arg1?: any, ...params: any[]): boolean => true; // eslint-disable-line @typescript-eslint/no-unused-vars

const defaultLogger: Logger = {
  debug: defaultLogFunction,
  error: defaultLogFunction,
  fatal: defaultLogFunction,
  info: defaultLogFunction,
  trace: defaultLogFunction,
  warn: defaultLogFunction,
};

const noOpActivatedRoute: ActivatedRoute = { route: '' };

export class RegisterEventListener {
  static getInstance() {
    if (RegisterEventListener.instance == null) {
      RegisterEventListener.instance = new RegisterEventListener();
    }

    return RegisterEventListener.instance;
  }

  static reset() {
    RegisterEventListener.instance = null;
  }

  private static instance: Maybe<RegisterEventListener> = null;

  private _initError: Error | undefined;

  private activatedRoute = noOpActivatedRoute;

  private featureFlags!: FeatureFlagsService<string>;

  private dispatcher!: FSADispatcher;

  private dependenciesProvider?: DependenciesProvider<
    'activatedRoute' | 'dispatcher' | 'featureFlags' | 'translator' | 'additionalDependencies' | 'userInfo'
  >;

  private registerEventHandler?: RegisterEventHandler;

  private logger: Logger = defaultLogger;

  private translator!: Translator;

  private userInfo!: Tm1UserInfo;

  hasInitError(): this is { initError: Error } {
    return typeof this.initError === 'object';
  }

  get initError(): Error | undefined {
    return this._initError;
  }

  async initialize(rootElement: GlobalEventHandlers) {
    this.dispatcher = new FSADispatcher(this.logger);
    const { containerOptions, userInfo } = await this.getConfig();
    this.featureFlags = new FeatureFlagsService(containerOptions?.enabledFeatures || []);
    this.userInfo = userInfo;
    this.translator = await AppTranslator.create({
      locale: this.userInfo?.locale || DEFAULT_LANGUAGE,
    });

    /**
     * This is the `Sales` custom implementation of the `DependencyProvider`
     *
     *   It is based on https://git.tmaws.io/tm1/tm1/-/blob/master/packages/app/src/injection-tokens/providers/tm1-dependencies.provider.ts
     *
     * The goal is to mimic what is done for UFO so we can use the same dependency injection pattern in both Sales and UFO.
     *
     */
    this.dependenciesProvider = new DependenciesProvider(
      {
        activatedRoute: {
          v1: () => this.activatedRoute,
        },
        additionalDependencies: {
          v1: ({ collectedDependencies }) => collectedDependencies,
        },
        dispatcher: {
          v1: () => this.dispatcher,
        },
        featureFlags: {
          v1: () => this.featureFlags,
        },
        logger: {
          v1: () => this.logger,
        },
        translator: {
          v1: () => this.translator,
        },
        userInfo: {
          v1beta1: () => _.cloneDeep(this.userInfo),
        },
      },
      this.logger,
    );

    this.registerEventHandler = new RegisterEventHandler(this.dependenciesProvider);
    this.registerEventHandler.addEventListener(rootElement);
  }

  private async getConfig() {
    try {
      return await tm1ApiClient.getBootConfig();
    } catch (e: unknown) {
      this._initError = e as Error;
      return [];
    }
  }
}
