import { renderInputComponent } from '@ticketmaster/tm1pos-web-shared/components/InputComponent';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import { AuthOffCreditCard } from '../../components/CreditCard';
import CreditCardSwipeInputBridge from '../CreditCardSwipeInputBridge';
import ManualCardEntry from './ManualCardEntry';
import type { AuthorizationOffValidator } from './AuthorizationOffValidator';
import type { Props as ManualCardEntryProps } from './ManualCardEntry';
import type { InjectedIntl } from 'react-intl';

type InjectedProps = {
  intl: InjectedIntl;
};
export type Props = {
  placeholder: string;
  cardFormLabels: any;
  fieldNames: any;
  validator: AuthorizationOffValidator;
  manualCardEntry: ManualCardEntryProps;
};

const AuthorizationOffForm = ({
  placeholder,
  manualCardEntry,
  cardFormLabels,
  fieldNames,
  validator,
}: InjectedProps & Props) => (
  <div className="card-entry__wrapper">
    <ManualCardEntry {...manualCardEntry} />
    <Field
      name={fieldNames.authCode}
      className="sdr-checkout__input sdr-checkout__input--authCode"
      component={renderInputComponent}
      placeholder={placeholder}
      validate={[validator.validateCode]}
    />
    {manualCardEntry.checked ? (
      <AuthOffCreditCard fieldNames={fieldNames} {...cardFormLabels} />
    ) : (
      <CreditCardSwipeInputBridge />
    )}
  </div>
);

export default injectIntl(AuthorizationOffForm);
