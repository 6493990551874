import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import faultTolerance from '@ticketmaster/tm1pos-web-shared/utils/faultTolerance';
import { call } from 'redux-saga/effects';
import {
  ARCHTICS_ADA_SEAT_AVAILABILITY_QUERY,
  ARCHTICS_SEAT_AVAILABILITY_QUERY,
  EVENT_DETAILS_QUERY,
} from 'services/sales-api-client/api-queries';
import { AVAILABILITY_SERVICE_URL, EVENT_DETAILS_URL } from '../../constants';
import type { AapiAvailabilityResult } from './model/aapi-availability-result';
import type { GetTicketTypesAndPricesResult } from './model/get-ticket-types-and-prices-result';
import type { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import type { Effect } from 'redux-saga/effects';

export function* getArchticsSeatStatusSnapshot(
  eventId: string,
  sellClasses: [],
  adaTypes?: [],
): Generator<Effect, GraphQLResult<AapiAvailabilityResult>, GraphQLResult<AapiAvailabilityResult>> {
  let query = ARCHTICS_SEAT_AVAILABILITY_QUERY;
  const args: { eventId: string; sellClasses: []; adaTypes?: [] } = {
    eventId,
    sellClasses,
  };
  if (adaTypes) {
    args.adaTypes = adaTypes;
    query = ARCHTICS_ADA_SEAT_AVAILABILITY_QUERY;
  }

  return yield call(fetchFromSalesApi, {
    url: AVAILABILITY_SERVICE_URL,
    query,
    options: {
      variables: args,
    },
  });
}

export function* getEventDetails(
  eventId: string,
  feeEquivalenceClassId: string,
  hostName: string,
): Generator<Effect, GraphQLResult<GetTicketTypesAndPricesResult>, GraphQLResult<GetTicketTypesAndPricesResult>> {
  return yield call(fetchFromSalesApi, {
    url: EVENT_DETAILS_URL,
    query: EVENT_DETAILS_QUERY,
    options: {
      variables: { eventId, feeEquivalenceClassId, hostName },
      faultToleranceHandler: faultTolerance.retryIfOpcodeLock,
    },
  });
}
