/* eslint-disable sonarjs/cognitive-complexity */

import { createSelector } from '@reduxjs/toolkit';
import { mapADATypeFromString } from '@ticketmaster/tm1pos-web-shared/ADA/mapper';
import { ADAType } from '@ticketmaster/tm1pos-web-shared/model/ADAType';
import { selectSelectedInventoryType } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import { isOpen as isPlaceOpen, isSeatNotSold } from '@ticketmaster/tm1pos-web-shared/utils/conditionals';
import { uniq } from 'lodash';
import { selectCartItemsLength } from '../../App/selectors';
import { selectAdaInfo, selectAdaTypes } from './adaTypes';
import {
  selectActivePriceLevels,
  selectBestAvailableModalPriceList,
  selectCurrentTicketTypeListRateIdByAuditPriceLevel,
  selectEventPriceLevelList,
  selectFlatActivePriceRanges,
  selectPriceRangeDisplayMode,
} from './priceRange';
import { selectTicketQty } from './ticketQty';
import {
  selectCurrentTicketType,
  selectPriceById,
  selectPriceByTicketType,
  selectTicketTypesLoadingState,
} from './ticketType';

export const selectIsmLoadFailed = (state) => state.event.get('ismLoadFailed');
export const selectIsmTotalPlaces = (state) => state.event.get('ismTotalPlaces');
export const selectLoadingAvailabilityData = (state) => state.event.get('isLoadingAvailabilityData');
export const selectShowBestAvailPriceModal = (state) => state.event.get('showBestAvailPriceModal');
export const selectIsDeselectingSeat = (state) => state.app.get('deselectingSeat');
export const selectGASections = (state) => state.event.get('gaSections');
export const selectArchticsSeatStatusState = (state) => state.event.get('archticsSeatStatusResponse');
export const selectPreviousArchticsSeatStatusState = (state) => state.event.get('previousArchticsSeatStatusResponse');
export const selectSeatStatuses = (state) => state.event.get('seatStatuses');

export const selectArchticsSeatStatus = createSelector(
  selectArchticsSeatStatusState,
  selectAdaTypes,
  (data, adaTypes) => {
    if (data && data.length) {
      return mapArchticsSeatStatus(data, adaTypes);
    }
    return null;
  },
);

export const selectPreviousArchticsSeatStatus = createSelector(
  selectPreviousArchticsSeatStatusState,
  selectAdaTypes,
  (previousData, adaTypes) => {
    if (previousData && previousData.length) {
      return mapArchticsSeatStatus(previousData, adaTypes);
    }
    return null;
  },
);

export function mapArchticsSeatStatus(data, adaTypes) {
  if (data && data.length) {
    const archticsSeatData = {};
    return data.reduce((acc, block) => {
      const blockProps = { ...block };
      delete blockProps.placeIds;
      if (block.placeIds) {
        if (blockProps.placeType && blockProps.placeType.id) {
          const placeType =
            adaTypes.find(({ id }) => id.toString() === blockProps.placeType.id.toString()) ?? blockProps.placeType;
          blockProps.placeType = { ...placeType };
        }
        block.placeIds.forEach((id) => {
          acc[id] = { id, ...blockProps };
        });
      }
      return acc;
    }, archticsSeatData);
  }
  return null;
}

export const selectUpdatedArchticsSeatStatus = createSelector(
  selectArchticsSeatStatus,
  selectPreviousArchticsSeatStatus,
  (data, previousData) => {
    if (previousData && data) {
      for (const key in previousData) {
        if (!data[key]) {
          const placeToAdd = previousData[key];
          data[key] = { ...placeToAdd, status: null };
        }
      }
    }
    return data;
  },
);

export const selectSeatStatusesSeatMap = createSelector(selectSeatStatuses, (seatStatuses) =>
  seatStatuses.seatMap ? Array.from(seatStatuses.seatMap.values()) : stableEmptyArray,
);

export const selectSeatStatus = createSelector(
  selectSeatStatusesSeatMap,
  selectUpdatedArchticsSeatStatus,
  (seatMap, archticsResponse = {}) => {
    let seats = seatMap;
    if (archticsResponse && Object.keys(archticsResponse).length) {
      if (seatMap.length) {
        seats = seats.map((item) => {
          const seatData = item;
          const id = item.id;
          const archticsItem = archticsResponse[id];
          if (archticsItem) {
            return Object.assign(item, archticsItem);
          }
          if (isPlaceOpen(item.status)) {
            seatData.status = null;
          }
          return seatData;
        });
      } else {
        seats = Object.values(archticsResponse);
      }
    }
    return seats;
  },
);

export const selectSegmentData = createSelector(
  selectSeatStatuses,
  selectCurrentTicketType,
  selectActivePriceLevels,
  selectPriceRangeDisplayMode,
  selectSelectedInventoryType,
  (ismrtData, currentTicketType, activePriceLevels, displayPriceRange, activeSellClass) =>
    groupGASeatStatusPlaces(ismrtData, activePriceLevels, displayPriceRange, activeSellClass).filter((facet) =>
      facet.ticketTypes.includes(currentTicketType.id),
    ),
);

// eslint-disable-next-line sonarjs/cognitive-complexity
function groupGASeatStatusPlaces(ismrtData, activePriceLevels, displayPriceRange, activeSellClass) {
  const groupedPlaces = {};
  if (ismrtData && ismrtData.seatMap) {
    for (const place of ismrtData.seatMap.values()) {
      if (place.available && place.generalAdmission) {
        const key = [place.sectionName, place.offerTicketTypeIds, place.accessibility, place.priceLevelGroupId];
        if (!groupedPlaces[key]) {
          groupedPlaces[key] = {
            accessibility: place.accessibility,
            available: place.available,
            priceLevels: [place.priceLevelGroupId],
            section: place.sectionName,
            ticketTypes: place.offerTicketTypeIds,
            count: 0,
            places: [],
            activePriceLevels,
            displayPriceRange,
            activeSellClass: activeSellClass?.name,
          };
        }
        // eslint-disable-next-line no-plusplus
        groupedPlaces[key].count++;
      }
    }
    return Object.values(groupedPlaces);
  }
  return [];
}
export const selectSegmentDataIdHash = createSelector(selectSegmentData, (data) => {
  const segments = {};
  data.forEach((item) => {
    if (checkDefined(item, ['section'])) {
      segments[item.section] = item;
    }
  });
  return segments;
});

export const selectAdditionalHostSeatData = createSelector(
  selectSeatStatusesSeatMap,
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (seatMap) => {
    if (seatMap.length) {
      return seatMap.reduce(
        (acc, place) => {
          acc.hostSeatData[place.id] = {
            ticketTypes: place.offerTicketTypeIds,
            priceLevel: place.priceLevelGroupId,
            accessibility: place.accessibility,
          };

          if (place.available) {
            if (place.generalAdmission) {
              const currentGAPriceLevel = acc.nonPlaceGaPriceLevels[place.priceLevelGroupId] ?? {
                count: 0,
                ticketTypes: [],
              };
              acc.nonPlaceGaPriceLevels[place.priceLevelGroupId] = {
                count: (currentGAPriceLevel.count += 1),
                ticketTypes: uniq([...currentGAPriceLevel.ticketTypes, ...place.offerTicketTypeIds]),
              };
            }

            const currentPriceLevel = acc.availablePriceLevels[place.priceLevelGroupId] ?? {
              count: 0,
              ticketTypes: [],
            };

            if (place.priceLevelGroupId) {
              acc.availablePriceLevels[place.priceLevelGroupId] = {
                count: (currentPriceLevel.count += 1),
                ticketTypes: uniq([...currentPriceLevel.ticketTypes, ...place.offerTicketTypeIds]),
              };
            }

            acc.totalAvailable += 1;
          }

          return acc;
        },
        { hostSeatData: {}, availablePriceLevels: {}, nonPlaceGaPriceLevels: {}, totalAvailable: 0 },
      );
    }
    return { hostSeatData: null, availablePriceLevels: null, totalAvailable: 0 };
  },
);

export const determineColorId = (priceId, priceRates, isOpen) => {
  if (isOpen && priceId && priceRates) {
    const priceRate = priceRates.find((r) => r.id === priceId);
    if (!priceRate) {
      return '';
    }
    if (!priceRate.priceLevels) {
      return priceId;
    }
    return priceRate.priceLevels[0];
  }
  return '';
};

export const matchQualifiers = (placeQualifiers, ticketTypeQualifiers) => {
  let matched = false;
  if (placeQualifiers && placeQualifiers.length) {
    matched = placeQualifiers.every(({ key, value }) => {
      const ticketTypeMask = ticketTypeQualifiers.charAt(key);
      return ticketTypeMask === value;
    });
  }
  return matched;
};

const TICKET_TYPE_HEX_MAP_REVERTED = {
  '00': 'OPEN',
  '01': 'ADULT',
  '02': 'J-TYPE',
  '03': 'G-TYPE',
  '04': 'K-TYPE',
  '05': 'L-TYPE',
  '06': 'M-TYPE',
  '07': 'V-TYPE',
  '08': 'COMP',
  '09': 'B-TYPE',
  '0A': 'N-TYPE',
  '0B': 'T-TYPE',
  '0C': 'W-TYPE',
  '0D': 'D-HOLD',
  '0E': 'E-HOLD',
  '0F': 'F-HOLD',
  10: '1-HOLD',
  11: '2-HOLD',
  12: '3-HOLD',
  13: '4-HOLD',
  14: '5-HOLD',
  15: '6-HOLD',
  16: '7-HOLD',
  17: '8-HOLD',
  18: '9-HOLD',
  19: 'HOLD',
  '1A': '===',
  '1B': 'REFUND',
  '1C': 'PREOUT',
  '1D': 'PREBOX',
  '1E': '???',
  '1F': '---',
};
export const getTicketTypeName = (code) => {
  if (!code || code.length < 2) {
    return '';
  }

  const placeInventoryType = code.slice(-2);

  return TICKET_TYPE_HEX_MAP_REVERTED[placeInventoryType] || '';
};

const TICKET_TYPE_LENGTH = 12;
export const removeTicketTypeFlags = (cache, ticketType) => {
  const ticketTypeWithoutFlagsKey = ticketType;
  let ticketTypeWithoutFlags = cache.get(ticketTypeWithoutFlagsKey);

  if (ticketTypeWithoutFlags === undefined) {
    ticketTypeWithoutFlags =
      !ticketType || ticketType.length !== TICKET_TYPE_LENGTH
        ? ticketType
        : ticketType.replace(/^(.{8}).{2}(.{2})$/, '$100$2');

    cache.set(ticketTypeWithoutFlagsKey, ticketTypeWithoutFlags);
  }

  return ticketTypeWithoutFlags;
};

const isAdaCompanion = (placeTypeName) => mapADATypeFromString(placeTypeName) === ADAType.COMPANION;

export const calculateCompanionWarning = (isAccessible, showFilters, placeTypeName, cartLength) =>
  isAccessible && showFilters && isAdaCompanion(placeTypeName) && cartLength < 1;

export const calculateAdaPlaceType = (placeType, isSeatAccessible, isHost, hostPlaceData, adaInfo) => {
  let adaPlaceType = placeType;
  let adaType;
  let adaId;

  if (isSeatAccessible) {
    if (isHost && hostPlaceData.accessibility.length) {
      adaType = getAdaType(hostPlaceData.accessibility[0], adaInfo);
      adaId = hostPlaceData.accessibility[0];
    } else if (!isHost) {
      adaType = placeType.name;
      adaId = placeType.id[0] || placeType.id.toString();
    }

    if (adaType) {
      adaPlaceType = {
        id: adaId,
        name: adaType,
      };
    }
  }

  return adaPlaceType;
};

const getAdaType = (adaId, adaInfo) =>
  adaInfo.adaTypes.filter((type) => type.id.includes(adaId)).map((type) => type.label)[0];

export const calculateClassName = (status, hasPriceInfo, adaFilter) => {
  if (isSeatNotSold(status) && hasPriceInfo && adaFilter) {
    return 'tool-tip-data-item__price';
  }

  return 'tool-tip-data-item__NAprice';
};

export const calculateIsSeatAccessible = ({ isHost, hostPlaceData }, { isArchtics, adaInfo, placeType }) => {
  const isHostAccessible = isHost ? hostPlaceData.accessibility?.length : false;
  const isArchticsAccessible = isArchtics ? adaInfo.selectedAdaTypesIds.includes(placeType.id.toString()) : false;

  return isHostAccessible || isArchticsAccessible;
};

const initMap = () => ({
  count: {},
  countByAdaType: {},
  mapSellTypeAndPriceLevel: {},
  maps: [],
  seatsStatusById: new Map(),
  sellTypeAvailability: {},
  total: 0,
});
const stableEmptyMap = initMap();
const stableEmptyArray = [];
let map = stableEmptyMap;
/**
 * Documentation for this selector can be found here: https://confluence.livenation.com/display/SDR/SeatStatus+Selectors+VS+ISM
 */
export const selectAvailableSeats = createSelector(
  selectSeatStatus,
  selectCurrentTicketType,
  selectPriceById,
  selectFlatActivePriceRanges,
  selectActivePriceLevels,
  selectAdditionalHostSeatData,
  selectAdaInfo,
  selectCartItemsLength,
  selectPriceByTicketType,
  selectCurrentTicketTypeListRateIdByAuditPriceLevel,
  selectSelectedInventoryType,
  (
    places,
    currentTicketType,
    priceById,
    activePriceRange,
    activePriceLevelsIds,
    additionalHostSeatData,
    adaInfo,
    cartLength,
    ttPriceRates,
    rateIdByAuditPriceLevel,
    inventoryType,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const { hostSeatData } = additionalHostSeatData;

    map = stableEmptyMap;
    if (priceById && places?.length && currentTicketType?.id && ttPriceRates?.length) {
      const cache = new Map();

      map = places.reduce((acc, place) => {
        const {
          id,
          status,
          sectionId,
          rowId,
          rating,
          placeType = {},
          priceZone,
          ticketTypeEncoded,
          restrictedOfferSets,
          placeInventoryType,
          ticketType,
          generalAdmission,
        } = place;

        const isArchtics = !!placeType.id;
        const isHost = !!ticketTypeEncoded;

        const hostPlaceData = (hostSeatData ? hostSeatData[id] : null) || { ticketTypes: [] };

        const isSeatAccessible = calculateIsSeatAccessible(
          { isHost, hostPlaceData },
          { isArchtics, adaInfo, placeType },
        );

        const adaPlaceType = calculateAdaPlaceType(placeType, isSeatAccessible, isHost, hostPlaceData, adaInfo);

        let sellClass = '';
        let sellClassName = '';
        let isInSelectedInventoryType = true;

        if (isHost) {
          sellClass = removeTicketTypeFlags(cache, ticketTypeEncoded);
          sellClassName = getTicketTypeName(placeInventoryType);
          isInSelectedInventoryType = sellClassName === inventoryType?.name;

          if (inventoryType?.name === 'OPEN') {
            isInSelectedInventoryType =
              isInSelectedInventoryType && hostPlaceData.ticketTypes.includes(currentTicketType.id);
          } else if (restrictedOfferSets) {
            // Is in selected qualified hold
            isInSelectedInventoryType =
              ticketType.offerSets.map((offerSet) => offerSet.id).includes(inventoryType?.name) &&
              inventoryType?.parent === sellClassName;
          }
        }

        if (isArchtics) {
          const disregardHoldIfArchticsAda = adaInfo.showFilters && isSeatAccessible;
          if (!disregardHoldIfArchticsAda) {
            isInSelectedInventoryType = String(adaPlaceType.id) === inventoryType?.id;
            sellClass = String(adaPlaceType.id);
            sellClassName = adaPlaceType.name;
          } else {
            isInSelectedInventoryType = true;
            sellClass = inventoryType?.id;
            sellClassName = inventoryType?.name;
          }
        }

        let adaFilter;
        if (isSeatAccessible && adaInfo.showFilters) {
          const inSelectedADATypes = adaInfo.selectedAdaTypesIds.find((adaId) => adaId === adaPlaceType.id);
          adaFilter = !!inSelectedADATypes;
        } else {
          adaFilter = !adaInfo.showFilters && !isSeatAccessible;
        }

        const priceId = rating && rating.id ? rating.id : rateIdByAuditPriceLevel[priceZone];
        const hasPriceInfo = priceId && priceById[priceId];
        let priceInfo;
        if (hasPriceInfo) {
          priceInfo = {
            ...priceById[priceId],
          };
        }

        const isInSellableStatus = isPlaceOpen(status);
        const isInSellableStatusAndSelectedInventoryType = isInSellableStatus && isInSelectedInventoryType;
        let filtered = true;

        if (isSeatNotSold(status) && hasPriceInfo) {
          let filteredByPrice = false;
          if (adaFilter) {
            if (!acc.count[priceId]) {
              acc.count[priceId] = { ...priceId, count: 0 };
            }

            if (activePriceRange.length) {
              const isInSelectedPriceRange = activePriceRange.indexOf(priceId) > -1;
              filtered = !(isInSelectedPriceRange && isInSellableStatusAndSelectedInventoryType);
              filteredByPrice = filtered;
            } else if (activePriceLevelsIds.length) {
              const isInSelectedPriceLevel = activePriceLevelsIds.find((levelId) => priceId === levelId);
              filtered = !isInSelectedPriceLevel || !isInSellableStatusAndSelectedInventoryType;
              filteredByPrice = filtered;
            } else {
              filtered = !isInSellableStatusAndSelectedInventoryType;
            }

            if (isInSellableStatusAndSelectedInventoryType) {
              acc.count[priceId].count += 1;
              acc.total += 1;
            }
          }

          if (adaPlaceType.id && !acc.countByAdaType[adaPlaceType.id]) {
            acc.countByAdaType[adaPlaceType.id] = 0;
          }

          if (adaPlaceType.id && !filteredByPrice) {
            acc.countByAdaType[adaPlaceType.id] += 1;
          }
        }

        if (isInSellableStatus) {
          if (acc.sellTypeAvailability[sellClass]) {
            acc.sellTypeAvailability[sellClass] += 1;
          } else {
            acc.sellTypeAvailability[sellClass] = 1;
          }

          if (acc.mapSellTypeAndPriceLevel[sellClass]) {
            if (!acc.mapSellTypeAndPriceLevel[sellClass].includes(priceId)) {
              acc.mapSellTypeAndPriceLevel[sellClass].push(priceId);
            }
          } else {
            acc.mapSellTypeAndPriceLevel[sellClass] = [priceId];
          }
        }

        const seat = {
          id,
          filtered,
          rating,
          priceId,
          colorId: determineColorId(priceId, ttPriceRates, isInSellableStatusAndSelectedInventoryType),
          status,
          price: priceInfo,
          ticketTypeDescription: currentTicketType.name,
          ticketTypeEncoded,
          sectionId,
          rowId,
          className: calculateClassName(status, hasPriceInfo, adaFilter),
          isOpen: isInSellableStatusAndSelectedInventoryType,
          sellClass,
          sellClassName,
          isAccessible: isSeatAccessible,
          placeType: adaPlaceType,
          showCompanionWarning: calculateCompanionWarning(
            isSeatAccessible,
            adaInfo.showFilters,
            adaPlaceType.name,
            cartLength,
          ),
          accessibility: isSeatAccessible && isHost ? hostPlaceData.accessibility : null,
          generalAdmission,
        };
        acc.maps.push(seat);
        acc.seatsStatusById.set(seat.id, seat.status);
        return acc;
      }, initMap());
    }

    return map;
  },
);

export const selectSellTypeAvailabilityCounts = createSelector(
  selectAvailableSeats,
  ({ sellTypeAvailability, mapSellTypeAndPriceLevel }) => ({ sellTypeAvailability, mapSellTypeAndPriceLevel }),
);

export const selectPriceListAvailabilityCounts = createSelector(
  selectAvailableSeats,
  selectAdditionalHostSeatData,
  selectCurrentTicketType,
  selectSellTypeAvailabilityCounts,
  selectSelectedInventoryType,
  (
    availableSeats,
    { availablePriceLevels = {}, nonPlaceGaPriceLevels = {} },
    currentTicketType,
    { mapSellTypeAndPriceLevel: sellTypesAvailability },
    currentInventoryType,
  ) => {
    const gaPriceLevelEntries = Object.entries(nonPlaceGaPriceLevels);
    const isCountByPriceLevelLoaded = availableSeats.count && Object.keys(availableSeats.count).length > 0;

    if (isCountByPriceLevelLoaded || gaPriceLevelEntries.length > 0) {
      return gaPriceLevelEntries.reduce(
        (acc, [key, value]) => {
          const hasCurrentTicketType = value.ticketTypes.indexOf(currentTicketType.id) > -1;
          const hasCurrentSellClass =
            sellTypesAvailability[currentInventoryType?.id] &&
            sellTypesAvailability[currentInventoryType?.id].includes(key);

          if (value.hasPlaces || !hasCurrentTicketType || !hasCurrentSellClass) {
            return acc;
          }
          if (acc[key]) {
            acc[key].count += value.count;
          } else {
            acc[key] = value;
          }
          return acc;
        },
        { ...availableSeats.count },
      );
    }

    if (availablePriceLevels) {
      return Object.entries(availablePriceLevels).reduce((acc, [key, value]) => {
        acc[key] = { ...value, count: 0 };
        return acc;
      }, {});
    }

    return availablePriceLevels;
  },
);

export const selectBestAvailablePriceListWithCount = createSelector(
  selectPriceListAvailabilityCounts,
  selectBestAvailableModalPriceList,
  (priceListCount, priceList = {}) =>
    priceList.map((item) => {
      const count = priceListCount && priceListCount[item.id] ? priceListCount[item.id].count : 0;
      return { ...item, count };
    }),
);

export const selectPriceLevelsWithAvailability = createSelector(
  selectEventPriceLevelList,
  selectPriceListAvailabilityCounts,
  selectIsmLoadFailed,
  (priceLevels, priceLevelsWithCount, ismLoadFailed) => {
    if (!priceLevelsWithCount && !ismLoadFailed) {
      return [];
    }
    return priceLevels.map((priceLevel) => {
      const seatCount =
        priceLevelsWithCount && priceLevelsWithCount[priceLevel.id] ? priceLevelsWithCount[priceLevel.id].count : 0;
      return {
        ...priceLevel,
        disabled: (!ismLoadFailed && seatCount === 0) || (priceLevel.isNotAvailableInCurrentTicketType ?? false),
      };
    });
  },
);

export const selectModalOrBA = createSelector(
  selectTicketQty,
  selectShowBestAvailPriceModal,
  selectBestAvailablePriceListWithCount,
  (ticketQty, showBestAvailPriceModal, priceList = []) => {
    const prices = priceList.reduce((acc, price) => {
      if (price && price.count >= ticketQty) {
        acc.push(price);
      }
      return acc;
    }, []);
    return {
      // launchModal: false, // showBestAvailPriceModal || (prices.length > 1),
      launchModal: prices.length !== 1 || showBestAvailPriceModal,
      defaultPrices: prices.length ? [prices[0].id] : [],
    };
  },
);
export const selectTotalAvailableSeats = createSelector(
  selectAdditionalHostSeatData,
  selectAvailableSeats,
  ({ hostSeatData, totalAvailable }, availableMap = { total: 0 }) => {
    if (availableMap.total > 0) {
      return availableMap.total;
    }
    if (hostSeatData) {
      return totalAvailable;
    }
    return null;
  },
);

export const selectSeatStatusTotals = createSelector(
  selectTotalAvailableSeats,
  selectIsmTotalPlaces,
  (available, total) => ({ available, total }),
);

export const selectAvailabilityStatusLoading = createSelector(
  selectTicketTypesLoadingState,
  selectLoadingAvailabilityData,
  (ticketTypesLoading, availabilityLoading) => ticketTypesLoading || availabilityLoading,
);
