import { I18nTranslator } from '@ticketmaster/tm1-core';
import { WEB_NAMESPACE_TRANSIFEX } from '@ticketmaster/tm1pos-web-shared/translations';
import {
  SHARED_NAMESPACE_TRANSIFEX,
  TMDS_NAMESPACE_TRANSIFEX,
} from '@ticketmaster/tm1pos-web-shared/translations/translations-constants';
import { TM1_URL } from '../../constants';
import type { TranslatorOpts } from '@ticketmaster/tm1-core';

export class AppTranslator extends I18nTranslator {
  static readonly NAMESPACES = [WEB_NAMESPACE_TRANSIFEX, SHARED_NAMESPACE_TRANSIFEX, TMDS_NAMESPACE_TRANSIFEX];

  static override async create(opts?: TranslatorOpts) {
    return super.create({
      ...opts,
      ns: AppTranslator.NAMESPACES,
      backendHost: TM1_URL,
      keySeparator: '.',
    });
  }
}
