import { injectIntl } from 'react-intl';
import { CreditCardForm } from '../../components/CreditCard';
import CreditCardSwipeInputBridge from '../CreditCardSwipeInputBridge';
import ManualCardEntry from './ManualCardEntry';
import type { Props as ManualCardEntryProps } from './ManualCardEntry';
import type { InjectedIntl } from 'react-intl';

type InjectedProps = {
  intl: InjectedIntl;
};
export type Props = {
  cardFormLabels: any;
  fieldNames: any;
  manualCardEntry: ManualCardEntryProps;
};

const StandardAuthorizationOnForm = ({ manualCardEntry, cardFormLabels, fieldNames }: InjectedProps & Props) => (
  <div className="card-entry__wrapper">
    <ManualCardEntry {...manualCardEntry} />
    {manualCardEntry.checked ? (
      <CreditCardForm fieldNames={fieldNames} {...cardFormLabels} />
    ) : (
      <CreditCardSwipeInputBridge />
    )}
  </div>
);

export default injectIntl(StandardAuthorizationOnForm);
