import { TransactionStatus } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-types';
import { validMonth, validYear } from '@ticketmaster/tm1pos-web-shared/utils';
import creditCardType from 'credit-card-type';
import { select } from 'redux-saga/effects';
import { ARCHTICS_EVENT_TYPE } from '../../../EventDetailPage/constants';
import { selectQualifiedHolds } from '../../../EventDetailPage/selectors/main';
import { decodeReceiptMessage, encryptCardData, hashCreditCardNumber } from './checkout-utils';
import { AMERICANEXPRESS, CARD, OTHER } from './constants';
import { generatePaymentMethodObject } from './selectors';
import { transformCardType } from './utils';
import type { ApiExpiration } from './api.expiration';
import type { ApiCardDetails, ApiPayment } from './api.payment';
import type { PaymentData } from './payment.data';
import type { HostPermissions } from '@ticketmaster/tm1pos-web-shared/model/host-permissions';
import type { EmvTransaction } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-types';

export const getApiPayment = (
  currentEvent: any,
  paymentData: PaymentData,
  hostPermissions: HostPermissions | undefined,
  hostName: string | undefined,
  fullName: string,
  availablePaymentMethodsForCheckout: any,
  paymentCertificate: any | undefined,
  isEmvSupported: boolean,
  transaction: EmvTransaction,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ApiPayment => {
  const isArchticsEvent = currentEvent.type === ARCHTICS_EVENT_TYPE;
  let autoAuthorizeCard;
  let applicationIdentifier;
  const {
    type,
    paymentInfo,
    accountNumber,
    creditCardTypes,
    authNumber,
    holderName: paymentHolderName,
    amount,
    method,
    id,
  } = paymentData;
  let { expiration } = paymentData;
  const payment: ApiPayment = { amount, method, id } as ApiPayment;
  const hasHostPermissions = hostPermissions && hostName && hostName in hostPermissions;
  const holderName = paymentHolderName || fullName;
  if (hasHostPermissions) {
    autoAuthorizeCard = hostPermissions[hostName].mustAuthorizeCards;
  }
  if (type === CARD) {
    if (transaction.paymentReference) {
      payment.cardDetails = { emv: { paymentReference: transaction.paymentReference } } as ApiCardDetails;
    } else {
      const baseCreditCardDetails = {
        autoAuthorizeCard,
        authNumber,
        certificateId: paymentCertificate?.id,
      };
      let cardDetails: ApiCardDetails = {} as ApiCardDetails;

      if (isEmvSupported && transaction.status !== TransactionStatus.NONE) {
        expiration = {
          month: validMonth(transaction.expirationMonth, null),
          year: validYear(transaction.expirationYear, null),
        } as ApiExpiration;

        if (!expiration?.month || !expiration?.year) {
          expiration = undefined;
        }
        const defaultAuthCode = `${transaction.token}${transaction.transactionId}`;
        const approvalResult = transaction.response;
        const { approvalCode, cardEntryMode, terminalVerificationResult, transactionType, terminalId, applicationId } =
          decodeReceiptMessage(transaction.merchantReceiptData || undefined, {
            approvalCode: defaultAuthCode,
          });
        applicationIdentifier = transaction.aid || applicationId;
        cardDetails = {
          emv: {
            approvalCode,
            approvalResult,
            hostToken: transaction.hostToken,
            transactionId: transaction.transactionId,
            token: transaction.token,
            terminalId,
            terminalVerificationResult,
            cardEntryMode,
            transactionType,
            applicationIdentifier,
          },
          expiration, // don't pass expiration if monthYear is undefined
          holderName: transaction.nameOnCard ? transaction.nameOnCard : holderName,
          token: transaction.token,
          creditCardType: transformCardType(transaction.paymentDetails.cardType || undefined),
          creditCardMask: transaction.paymentDetails.lastFourDigits,
          creditCardBin: transaction.bin,
          creditCardHash: transaction.token,
          creditCardNumber: transaction.token || 0,
        } as ApiCardDetails;
        payment.method = generatePaymentMethodObject(
          { paymentMethod: type } as any,
          availablePaymentMethodsForCheckout,
          transaction.paymentDetails,
        ).method;
      } else {
        cardDetails = {
          creditCardMask: accountNumber?.substr(-4) || null,
          creditCardType: accountNumber
            ? transformCardType(creditCardType(accountNumber)[0].type)
            : creditCardTypes.value.replace(/ /g, '').toUpperCase(),
          creditCardNumber: accountNumber || 0,
          expiration,
          holderName,
        } as ApiCardDetails;
        cardDetails.creditCardHash = hashCreditCardNumber(accountNumber);
        cardDetails.token = encryptCardData(accountNumber, paymentCertificate);
      }

      if (!isArchticsEvent && cardDetails.creditCardType === AMERICANEXPRESS) {
        cardDetails.creditCardType = 'AMERICAN EXPRESS'; // host hack
      }
      payment.cardDetails = Object.assign(baseCreditCardDetails, cardDetails);
    }
  }

  if (type === OTHER) {
    payment.otherMOPDetails = {
      expiration,
      paymentInfo,
      autoAuthorize: hasHostPermissions ? autoAuthorizeCard : true,
    };
  }
  return payment;
};

export function* cartItemsContainsQualifiedHolds(cartItems: any[] = []) {
  if (cartItems.length === 0) {
    return false;
  }

  const qualifiedHolds: ReturnType<typeof selectQualifiedHolds> = yield select(selectQualifiedHolds);
  if (!qualifiedHolds || qualifiedHolds.length === 0) {
    return false;
  }

  return cartItems.some(({ sellClass: { id: cartItemSellClassId } }) =>
    qualifiedHolds.some(({ id: qualifiedHoldId }: any) => cartItemSellClassId === qualifiedHoldId),
  );
}
