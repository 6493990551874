import LoadingComponent from '@ticketmaster/tm1pos-web-shared/components/LoadingComponent';
import { TIMEOUT_DELAY } from '@ticketmaster/tm1pos-web-shared/constants';
import { JWT_TOKEN_EXPIRED } from '@ticketmaster/tm1pos-web-shared/store/actions-constants';
import { getUserClaims } from '@ticketmaster/tm1pos-web-shared/utils/oauth-utils';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addTimeout, WATCH_ALL } from 'redux-timeout';
import { useAppSelector } from '../../../../store/hooks';
import userManager from '../../../../utils/userManager';
import { startLogin, userInactivity } from '../../actions';
import { START_TIMEOUT } from '../../actions-constants';
import { selectUserLoadingState } from '../../selectors';
import type { User } from 'oidc-client';

const AUTHENTICATION_FLOW_PATHS = ['/authenticate', '/error', '/silentrenew'];

export const SessionCheck = ({
  location: { pathname },
  authenticatedUserElement,
  unknownUserElement,
}: {
  location: Pick<Location, 'pathname'>;
  authenticatedUserElement: JSX.Element;
  unknownUserElement: JSX.Element;
}) => {
  const dispatch = useDispatch();
  const setupInactivityAutoLogout = useCallback(() => {
    dispatch({
      type: START_TIMEOUT,
      time: Date.now(),
    });

    dispatch(
      addTimeout(TIMEOUT_DELAY, WATCH_ALL, () => {
        dispatch(userInactivity());
      }),
    );
  }, [dispatch]);

  const isUserAuthenticated = !!getUserClaims();
  const isUserLoading = useAppSelector(selectUserLoadingState);
  const isAuthLocationPath = AUTHENTICATION_FLOW_PATHS.includes(pathname);

  const canProceedWithAppLoad = !isAuthLocationPath && !isUserLoading;

  useLayoutEffect(() => {
    userManager.getUser().then((user: User | null) => {
      if (user?.expired) {
        dispatch({ type: JWT_TOKEN_EXPIRED, content: { message: 'Session expired' } });
      }
    });
  });

  useEffect(() => {
    if (canProceedWithAppLoad) {
      if (isUserAuthenticated) {
        setupInactivityAutoLogout();
      } else {
        dispatch(startLogin());
      }
    }
  }, [dispatch, setupInactivityAutoLogout, canProceedWithAppLoad, isUserAuthenticated]);

  if (canProceedWithAppLoad && isUserAuthenticated) {
    return authenticatedUserElement;
  }

  if (isAuthLocationPath) {
    return unknownUserElement;
  }

  return <LoadingComponent largeGear />;
};
