import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ISMRT_PLACE_UPDATES_URL } from '../../constants';
import { ISMRT_PLACE_UPDATE_QUERY } from './model/ismrt-queries';
import type { IsmrtPlaceUpdate } from '../../containers/EventDetailPage/model/IsmrtPlaceData';

const RECONNECTION_ATTEMPTS = 10;
const TIMEOUT = 10000;
const ISMRT_SUBSCRIPTION_ERROR_MESSAGE = 'IsmrtSubscriptionClient: Websocket error while subscribing to place updates.';

export class IsmrtSubscriptionClient {
  private readonly eventId: string;

  private readonly subscriptionClient: SubscriptionClient;

  private readonly apolloClient: ApolloClient<any>;

  private readonly onError: (error: Error) => void;

  private readonly onReconnect: () => void;

  constructor(token: string, eventId: string, onError: (errors: Error) => void, onReconnect: () => void) {
    this.eventId = eventId;
    this.onError = onError;
    this.onReconnect = onReconnect;
    this.subscriptionClient = new SubscriptionClient(ISMRT_PLACE_UPDATES_URL, {
      reconnect: true,
      timeout: TIMEOUT,
      reconnectionAttempts: RECONNECTION_ATTEMPTS,
      connectionParams: {
        token,
        eventId,
      },
    });

    this.subscriptionClient.onError(this.handleErrors);
    this.subscriptionClient.onReconnected(this.handleReconnection);

    this.apolloClient = new ApolloClient({
      link: new WebSocketLink(this.subscriptionClient),
      cache: new InMemoryCache(),
    });
  }

  public subscribeToPlaceUpdates = (onPlacesUpdated: (placeUpdate: IsmrtPlaceUpdate) => void) => {
    this.apolloClient
      .subscribe({
        query: ISMRT_PLACE_UPDATE_QUERY,
        variables: {
          eventId: this.eventId,
        },
      })
      .subscribe({
        next: (updatedPlaces) => onPlacesUpdated(updatedPlaces.data.update),
        error: (sourceError: any) => this.handleErrors(sourceError),
      });
  };

  public unsubscribeFromPlaceUpdates = () => {
    this.subscriptionClient.unsubscribeAll();
    this.subscriptionClient.close();
  };

  private handleErrors = (sourceError: any) => {
    let errorToEmit: Error;

    if (sourceError instanceof Error) {
      errorToEmit = sourceError;
    } else {
      errorToEmit = new Error(ISMRT_SUBSCRIPTION_ERROR_MESSAGE);

      if (sourceError) {
        errorToEmit = Object.assign(errorToEmit, sourceError);
      }
    }

    this.onError(errorToEmit);
  };

  private handleReconnection = () => {
    this.onReconnect();
  };
}
