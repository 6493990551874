import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tm1Button } from '@tm1/design-system-react';
import classnames from 'classnames';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PrinterService } from '../../PrinterService';
import messages from '../../printing-message';
import { PrinterServiceFormSelector } from './Forms/PrinterServiceFormSelector';
import DropdownPrintingService from './Inputs/DropdownPrintingService';
import './EditPrinterConfiguration.scss';
const EditPrinterConfiguration = forwardRef(({ activePrinter, allowedServices, className, connectionError, loading, onPrinterValidChange, onRefreshPrinter, onSave, onCancel, printers, printerServiceRefreshed, readOnly, onPrintTestTicket, showTestPrinter, showValidationMessage, }, ref) => {
    const [printerService, setPrinterService] = useState();
    const [printer, setPrinter] = useState(null);
    useEffect(() => {
        if (activePrinter) {
            setPrinter(activePrinter);
            setPrinterService(activePrinter.printerService);
        }
    }, [activePrinter, setPrinter]);
    const onPrinterServiceChange = useCallback((newPrinterService) => {
        setPrinter(null);
        setPrinterService(newPrinterService);
        if (newPrinterService === PrinterService.Tmds || newPrinterService === PrinterService.QzTrayLocal) {
            onRefreshPrinter(newPrinterService);
        }
        onPrinterValidChange(false);
    }, [setPrinterService, setPrinter, onRefreshPrinter, onPrinterValidChange]);
    const onChangePrinter = useCallback((newPrinter) => {
        setPrinter(newPrinter);
        onPrinterValidChange(!!newPrinter);
    }, [onPrinterValidChange, setPrinter]);
    useImperativeHandle(ref, () => ({
        save: () => {
            onSave(printer);
        },
        cancel: () => {
            onCancel();
        },
    }));
    return (_jsxs("div", { className: classnames(['edit-printer-configuration', className]), children: [_jsx("div", { children: _jsx(DropdownPrintingService, { allowedServices: allowedServices, connectionError: connectionError, defaultService: printer === null || printer === void 0 ? void 0 : printer.printerService, disabled: loading, onChange: onPrinterServiceChange, readOnly: readOnly, showValidationMessage: showValidationMessage }) }), _jsx(PrinterServiceFormSelector, { connectionError: connectionError, defaultPrinter: printer, loading: loading, onChangePrinter: onChangePrinter, onRefreshPrinter: onRefreshPrinter, printerService: printerService, printerServiceRefreshed: printerServiceRefreshed, printers: printers, readOnly: readOnly, showValidationMessage: showValidationMessage }), showTestPrinter && printer ? (_jsx(Tm1Button, { className: "edit-printer-configuration__test-printer", size: "small", variant: "outline", disabled: loading, onClick: () => onPrintTestTicket(printer), children: _jsx(FormattedMessage, { ...messages.testPrinterConnectivity }) })) : null] }));
});
EditPrinterConfiguration.displayName = 'EditPrinterConfiguration';
export default EditPrinterConfiguration;
