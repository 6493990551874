import { renderCheckboxComponent } from '@ticketmaster/tm1pos-web-shared/components/Checkbox';
import { injectIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import messages from '../../../../messages';
import { normalizeFn } from '../../utils';
import type { InjectedIntl } from 'react-intl';
import './GlobalAccount.scss';

type InjectedProps = {
  intl: InjectedIntl;
  linkGlobalAccount: boolean;
};
export type Props = InjectedProps;
export const GlobalAccount = ({ intl, linkGlobalAccount }: Props) => (
  <div className="global-account-toggle">
    <Field
      className="oc-toggle-switch--small"
      checked={linkGlobalAccount}
      component={renderCheckboxComponent}
      id="linkGlobalAccount"
      name="linkGlobalAccount"
      normalize={normalizeFn}
      text={intl.formatMessage(messages.createGlobalAccount)}
    ></Field>
  </div>
);

const GlobalAccountWithIntl = injectIntl(GlobalAccount);
GlobalAccountWithIntl.displayName = 'GlobalAccount';
export default GlobalAccountWithIntl;
