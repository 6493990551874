import {
  iceGeometryLoadCompleted,
  iceGeometryLoadStarted,
  iceMetricsEventReport,
} from '../../../../../../middleware/tracing-actions';
import type { ICECore } from '../../../../../../modules/window';
import type { Dispatch } from '@reduxjs/toolkit';

export const setupTracingOnIceEvents = (ice: ICECore, storeDispatch: Dispatch) => {
  ice.on('ice-metrics', (event) => {
    const endTime = Date.now();
    const startTime = endTime - event.time;
    storeDispatch(
      iceMetricsEventReport({
        loadType: event.loadType,
        duration: event.time,
        startTime: new Date(startTime).toISOString(),
        endTime: new Date(endTime).toISOString(),
      }),
    );
  });

  ice.on('load-geometry-started', () => {
    storeDispatch(iceGeometryLoadStarted());
  });

  ice.on('load-geometry-complete', () => {
    storeDispatch(iceGeometryLoadCompleted());
  });
};
