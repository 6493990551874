import { Tm1Banner } from '@tm1/design-system-react';
import messages from '../messages';
import type { InjectedIntl } from 'react-intl';

type InjectedProps = {
  intl: InjectedIntl;
};

export const EditOtherChargesErrorBanner = ({ intl }: InjectedProps) => (
  <div className="edit-other-charges-grid__error-banner">
    <Tm1Banner title={intl.formatMessage(messages.otherChargesErrorBannerTitle)} variant="error">
      {intl.formatMessage(messages.otherChargesErrorBanner)}
    </Tm1Banner>
  </div>
);
