import { createAction } from '@reduxjs/toolkit';
import type { CartEventFee } from '../../services/sales-api-client/model/cart-event-fee';
import type { CartResponse } from '../../services/sales-api-client/model/cart-response';
import type { SetEventFeesErrorType } from '../../services/sales-api-client/model/set-cart-event-fees-response';

export type SetEventFeesPayload = {
  eventFees: CartEventFee[];
};

export type SetEventFeesErrorPayload = {
  error: SetEventFeesErrorType;
};

export const toggleEditOtherChargesModal = createAction('event/toggleEditOtherChargesModal');
export const setEventFees = createAction<SetEventFeesPayload>('eventFees/setEventFees');
export const setEventFeesLoading = createAction('eventFees/setEventFeesLoading');
export const setEventFeesSuccess = createAction<CartResponse>('eventFees/setEventFeesSuccess');
export const setEventFeesError = createAction<SetEventFeesErrorPayload>('eventFees/setEventFeesError');
