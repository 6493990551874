import { createAction, createSlice } from '@reduxjs/toolkit';
import { ReverseTransactionStatus, TransactionStatus } from './emvPaymentModule-types';
import { EMVService } from './EMVService';
export const initialState = {
    isEmvSupported: false,
    devices: 'loading',
    deviceId: null,
    rules: {
        shouldSelectDeviceFromList: false,
        shouldRequireFullName: false,
    },
    transaction: {
        paymentReference: null,
        paymentDetails: {
            cardType: null,
            lastFourDigits: null,
        },
        status: TransactionStatus.NONE,
        reverseStatus: ReverseTransactionStatus.NONE,
        error: null,
        expirationMonth: null,
        expirationYear: null,
        hostToken: null,
        token: null,
        response: null,
        merchantReceiptData: null,
        aid: null,
        bin: null,
        nameOnCard: null,
        merchantDetails: {
            address: null,
            city: null,
            state: null,
            country: null,
            phoneNumber: null,
        },
        transactionId: null,
    },
    emvMode: EMVService.None,
};
const emvPaymentModuleSlice = createSlice({
    name: 'emvPaymentModule',
    initialState,
    reducers: {
        setEmvSupported: (state, { payload: isEmvSupported }) => ({
            ...state,
            isEmvSupported,
        }),
        setPaymentReference: (state, { payload: paymentReference }) => ({
            ...state,
            transaction: {
                ...state.transaction,
                paymentReference,
            },
        }),
        setPaymentDevices: (state, { payload: devices }) => ({
            ...state,
            devices,
        }),
        setLoadingDevices: (state) => ({
            ...state,
            devices: 'loading',
        }),
        setDeviceId: (state, { payload: deviceId }) => ({
            ...state,
            deviceId,
        }),
        shouldSelectDeviceFromList: (state) => ({
            ...state,
            rules: {
                ...state.rules,
                shouldSelectDeviceFromList: true,
            },
        }),
        setPaymentDetails: (state, { payload: paymentDetails }) => ({
            ...state,
            transaction: {
                ...state.transaction,
                paymentDetails,
            },
        }),
        setStatus: (state, { payload: status }) => ({
            ...state,
            transaction: {
                ...state.transaction,
                status,
            },
        }),
        setReverseTransactionStatus: (state, { payload: reverseTransactionStatus }) => ({
            ...state,
            transaction: {
                ...state.transaction,
                reverseStatus: reverseTransactionStatus,
            },
        }),
        setFailedReverseTransaction: (state, { payload: reverseError }) => ({
            ...state,
            transaction: {
                ...state.transaction,
                reverseStatus: ReverseTransactionStatus.FAILURE,
                reverseError,
            },
        }),
        setError: (state, { payload: error }) => ({
            ...state,
            transaction: {
                ...state.transaction,
                error,
            },
        }),
        clearTransactionState: (state) => ({
            ...state,
            transaction: {
                ...initialState.transaction,
            },
        }),
        setTransaction: (state, { payload: transaction }) => ({
            ...state,
            transaction,
        }),
        shouldRequireFullName: (state) => ({
            ...state,
            rules: {
                ...state.rules,
                shouldRequireFullName: true,
            },
        }),
        setEmvMode: (state, { payload: emvMode }) => ({
            ...state,
            emvMode,
        }),
    },
});
export const initializeEmvPaymentModule = createAction(`${emvPaymentModuleSlice.name}/initialize`);
export const beginEmvTransaction = createAction(`${emvPaymentModuleSlice.name}/start`);
export const cancelEmvTransaction = createAction(`${emvPaymentModuleSlice.name}/cancel`);
export const acknowledgeEmvTransaction = createAction(`${emvPaymentModuleSlice.name}/acknowledge`);
export const reverseEmvTransaction = createAction(`${emvPaymentModuleSlice.name}/reverse`);
export const emvPaymentSuccessful = createAction(`${emvPaymentModuleSlice.name}/paymentSuccess`);
export const emvPaymentError = createAction(`${emvPaymentModuleSlice.name}/paymentError`);
export const emvRefundSuccessful = createAction(`${emvPaymentModuleSlice.name}/refundSuccess`);
export const emvRefundError = createAction(`${emvPaymentModuleSlice.name}/refundError`);
export const pollTransactionStatus = createAction(`${emvPaymentModuleSlice.name}/pollTransactionStatus`);
export const fetchPaymentDevices = createAction(`${emvPaymentModuleSlice.name}/devices`);
export const clearEmvTransaction = createAction(`${emvPaymentModuleSlice.name}/clear`);
export const { setEmvSupported, setPaymentReference, setDeviceId, setPaymentDetails, setStatus, setReverseTransactionStatus, setFailedReverseTransaction, setError, shouldSelectDeviceFromList, clearTransactionState, setPaymentDevices, setTransaction, shouldRequireFullName, setLoadingDevices, setEmvMode, } = emvPaymentModuleSlice.actions;
export const emvPaymentModuleReducer = emvPaymentModuleSlice.reducer;
