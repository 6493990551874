import { fetchFromSalesApi } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api';
import { call } from 'redux-saga/effects';
import { PCI_GRAPHQL_URL } from '../../constants';
import { USER_TRACKING_MUTATION } from './api-queries';
import type { GraphQLResult } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import type { CallEffect } from 'redux-saga/effects';

export function* callUserTracking(): Generator<CallEffect, GraphQLResult<boolean>, GraphQLResult<boolean>> {
  return yield call(fetchFromSalesApi, {
    url: PCI_GRAPHQL_URL,
    query: USER_TRACKING_MUTATION,
  });
}
