import _ from 'lodash';
import type { CartResponse } from './cart-response';

export type SetCartEventFeesResponse = {
  setCartEventFees: CartResponse;
};

export enum SetEventFeesErrorType {
  CART_ID_INVALID_OR_EXPIRED = 'CART_ID_INVALID_OR_EXPIRED',
  GENERIC_ERROR = 'GENERIC_ERROR',
}

export const getErrorTypeByErrorCode = (errorCode: string | undefined): SetEventFeesErrorType =>
  _.find(SetEventFeesErrorType, (errorTypeValue) => errorCode === errorTypeValue) ||
  SetEventFeesErrorType.GENERIC_ERROR;
