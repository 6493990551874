import { LogComponent, LogStatus } from '@ticketmaster/tm1pos-web-shared/errorHandling/constants';
import { logClientToCloud } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { put } from 'redux-saga/effects';
import type { ReceiptPaymentInfo } from './receipt-payment-info';
import type { OrderData } from '../../../../../models/order-data';
import type { CheckoutPayment } from '@ticketmaster/tm1pos-web-shared/model/checkout-payment';

const UNEXPECTED_PAYMENT_COUNT_MESSAGE =
  'Unexpected payment count. Payment amounts were not adjusted with actual checkout totals during delivery.';

export function* createReceiptPaymentInfos(payments: CheckoutPayment[], orderInfo: OrderData) {
  if (hasExpectedPaymentCount(payments)) {
    return combineReservePaymentWithActualCheckoutPrice(payments, orderInfo);
  }

  // Should not happen
  yield put(createUnexpectedPaymentCountLog(payments, orderInfo));
  return payments;
}

function combineReservePaymentWithActualCheckoutPrice(
  payments: CheckoutPayment[],
  orderInfo: OrderData,
): ReceiptPaymentInfo[] {
  const reservePayment = payments[0];
  const checkoutTotal = orderInfo.totals.total;

  return [
    {
      ...reservePayment,
      amount: { amount: checkoutTotal.amount, currency: checkoutTotal.currency },
    },
  ];
}

function hasExpectedPaymentCount(payments: any[]): boolean {
  return payments.length === 1;
}

function createUnexpectedPaymentCountLog(payments: any[], orderInfo: any) {
  return logClientToCloud({
    type: LogComponent.DELIVERY,
    status: LogStatus.ERROR,
    data: {
      message: UNEXPECTED_PAYMENT_COUNT_MESSAGE,
      payments,
      orderInfo,
    },
  });
}
